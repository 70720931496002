import { createContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";

let ProjectContext = createContext(null);

const PROJECT_QUERY = gql`
  query Project($projectId: ID!) {
    project(id: $projectId) {
      id
      active
      allowDataReplication
      name
      description
      createdBy
      end
      invoiceTemplate
      locationId
      popEnd
      popStart
      projectLogo
      projectNumber
      projectOwner
      start
      numberOfUnits
      projectFirmUnits {
        id
        firmUnitId
        project {
          id
          active
          name
          description
          createdBy
          end
          invoiceTemplate
          locationId
          popEnd
          popStart
          projectLogo
          projectNumber
          projectOwner
          start
          numberOfUnits
          publicPrivatePartnership
        }
      }
      childProjects {
        id
        active
        name
        description
        createdBy
        end
        invoiceTemplate
        locationId
        popEnd
        popStart
        projectLogo
        projectNumber
        projectOwner
        start
        numberOfUnits
        publicPrivatePartnership
      }
    }
  }
`;

function ProjectProvider({ children }) {
  const [projectId, setProjectId] = useState(null);
  const setProject = (project) => {
    console.log("SETTING PROJECT:");
    if (!project) {
      setProjectId(null);
      return;
    }
    setProjectId(project.id);
  };

  const [projectQuery, { data, error, called, refetch }] = useLazyQuery(
    PROJECT_QUERY,
    {
      onCompleted: (res) => {
        localStorage.setItem("project", res.project.id);
      },
      onError: (err) => {
        // debugger;
        localStorage.setItem("project", "");
        setProjectId(null);
      },
    }
  );
  useEffect(() => {
    const projectToQuery = projectId ?? localStorage.getItem("project");
    if (!projectToQuery) return;
    if (called) {
      refetch({
        projectId: projectToQuery,
      });
    } else {
      projectQuery({
        variables: {
          projectId: projectToQuery,
        },
      });
    }
  }, [projectId, projectQuery, called, refetch]);

  let value = { project: data ? data.project : null, setProject };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}

export { ProjectContext, ProjectProvider };
