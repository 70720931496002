import { gql, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSearchParamsState } from "src/utils/useSearchParamsState";
import SelectedAssignment from "./SelectedAssignment";

const INSPECTIONS_QUERY = gql`
  query Inspections($inspection: InputInspectionParams!) {
    inspections(inspection: $inspection) {
      id
      projectId
      inspectionLocation
      inspectionTypeId
      number
      contactName
      contactPhone
      residentFirstName
      residentLastName
      isDeleted
      inspectionAssignments {
        start
        id
      }
      status
      inspectionType {
        name
        id
      }
    }
  }
`;

export const InspectionTypeSelect = () => {
  const { controlNumber } = useParams();
  const [inspection, setInspection] = useSearchParamsState("inspection", "");
  const { data } = useQuery(INSPECTIONS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      inspection: {
        number: controlNumber,
      },
    },
  });

  if (!data) return <CircularProgress />;

  const currentInspection = data.inspections.find(
    (i: any) => i.id === inspection
  );

  // function getTypes() {
  //   let types: { id: string; name: string }[] = [];
  //   if (!data)
  //     return  types

  //   let inspectionTypesObject: any = {};
  //   data.inspections.forEach((inspection: any) => {
  //     inspectionTypesObject[inspection.inspectionType.id] =
  //       inspection.inspectionType.name;
  //   });

  //   return {
  //     types: Object.keys(inspectionTypesObject).map((key) => {
  //       return {
  //         id: key,
  //         name: inspectionTypesObject[key],
  //       };
  //     }),
  //   };
  // }

  // const types = getTypes();

  return (
    <>
      {inspection && <SelectedAssignment inspectionId={inspection} />}
    </>
  );
};
