import { useContext } from "react";
import { AuthContext } from "./utils/contexts/auth";
import { useLocation } from "react-router-dom";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Alerts from "./pages/Alerts";
import AssetManagement from "./pages/Logistics/AssetManagement";
import Notices from "./pages/Logistics/Production/notices";
import Reports from "./pages/Logistics/Production/reports";
import Shipping from "./pages/Logistics/Shipping";
import Reporting from "./pages/Reporting";
import Review from "./pages/Review";
import Invoicing from "./pages/Invoicing";
import Messaging from "./pages/Messaging";
import Forms from "./pages/Forms";
import UserProfile from "./pages/User";
import UserDashboard from "./pages/User/Dashboard";
import UserSettings from "./pages/User/Settings";
import Notifications from "./pages/Notifications";
// Project Routes
import Projects from "./pages/Projects/list";
import ProjectPeople from "./pages/Projects/People";
import Credentials from "./pages/Credentials";
import CredentialRequest from "./pages/Credentials/request";
import CredentialReview from "./pages/Credentials/review";
import People from "./pages/People";
import Assets from "./pages/Assets/Assets";
import AssetTypes from "./pages/Assets/Types";
import AssetAssignments from "./pages/Assets/Assignments";
import AssetMaintenance from "./pages/Assets/Maintenance";
import ProjectPeopleAdmin from "./pages/Admin/ProjectPeople";
import FirmPeopleAdmin from "./pages/Admin/FirmPeople";
import FirmUnitPeopleAdmin from "./pages/Admin/FirmUnitPeople";
import ProjectFirmUnitAdmin from "./pages/Admin/ProjectFirmUnit";
import FirmUnitAdmin from "./pages/Admin/FirmUnit";
import Users from "./pages/Users";
import ProjectFirms from "./pages/Projects/Firms";
import ProjectDetails from "./pages/Projects/Details";
import FirmUnits from "./pages/FirmManagement/FirmUnits/list";
import Firms from "./pages/FirmManagement/Firms/list";
import AdminProjects from "./pages/ProjectManagement/list";
import Firm from "./pages/FirmManagement/Firms/index";
import FirmUnit from "./pages/FirmManagement/FirmUnits/index";
import AdminProject from "./pages/ProjectManagement";
import Admin from "./pages/Admin";
import AdminUser from "./pages/Admin/UserManagement";
import AdminFirm from "./pages/Admin/FirmManagement";
import AdminDash from "./pages/Admin/Dashboard";
import AdminCredential from "./pages/Admin/Credential";
import AdminCredentialView from "./pages/Admin/CredentialView";
import AdminCategory from "./pages/Admin/Category";
import AdminRoles from "./pages/Admin/RoleManagement";
import AdminRole from "./pages/Admin/RoleView";
import FirmProfile from "./pages/FirmManagement/Profile";
import UsersList from "./pages/UserManagement/List";
import UserOnboarding from "./pages/Onboarding/User";
import FirmOnboarding from "./pages/Onboarding/Firm";
import PendingOnboarding from "./pages/Onboarding/Pending";
import RoleRequest from "./pages/Onboarding/RoleRequest";
import RoleRequestNonAuth from "./pages/Onboarding/RoleRequest/nonauth";
import RoleRequestReview from "./pages/Onboarding/RoleRequestReview";
import ReviewOnboarding from "./pages/Onboarding/Review";
import UsersRoleManagement from "./pages/UserManagement/RoleManagement";
import UserCalendar from "./pages/Events/UserCalendar";
import NotFound from "./pages/Page404";
import Inspections from "./pages/Inspections";
import InspectionAddresses from "./pages/Inspections/Addresses";
import InspectionCreate from "./pages/Inspections/Create";
import InspectionList from "./pages/Inspections/List";
import InspectionAssignments from "./pages/Inspections/Assignments";
import InspectionSchedule from "./pages/Inspections/Inspection/Schedule";
import InspectionEventLogs from "./pages/Inspections/Inspection/InspectionEventLogs";
import EnvironmentMap from "./pages/EnvironmentMapping/EnvironmentMap";
import { MyInspections } from "./pages/Inspections/MyInspections";
import GeoRules from "./pages/EnvironmentMapping/GeoRules";
import { DataReplicationRequests } from "./pages/Inspections/DataReplication";
import { DataReplicationRequestResults } from "./pages/Inspections/DataReplication/results";

// ----------------------------------------------------------------------
function RequireAuth({ children }) {
  let auth = useContext(AuthContext);
  let location = useLocation();
  if (!auth.checkToken()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

export default function Router() {
  return useRoutes([
    {
      path: "/app",
      element: (
        <RequireAuth>
          <DashboardLayout></DashboardLayout>
        </RequireAuth>
      ),
      children: [
        { element: <Navigate to="/app/dashboard" replace /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "details", element: <ProjectDetails /> },
        { path: "messaging", element: <Messaging /> },
        { path: "alerts", element: <Alerts /> },
        { path: "logistics/asset-management", element: <AssetManagement /> },
        { path: "logistics/production/notices", element: <Notices /> },
        { path: "logistics/production/reports", element: <Reports /> },
        { path: "logistics/shipping", element: <Shipping /> },
        { path: "reporting", element: <Reporting /> },
        { path: "review/*", element: <Review /> },
        { path: "invoicing", element: <Invoicing /> },
        { path: "forms", element: <Forms /> },
        { path: "admin", element: <Admin /> },
        { path: "project/credentials", element: <Credentials /> },
        {
          path: "project/credential/request/:id",
          element: <CredentialRequest />,
        },
        {
          path: "project/credential/review/:id",
          element: <CredentialReview />,
        },
        { path: "project/people", element: <ProjectPeople /> },
        { path: "project/firms", element: <ProjectFirms /> },
        { path: "assets/management", element: <Assets /> },
        { path: "assets/types", element: <AssetTypes /> },
        { path: "assets/assignments", element: <AssetAssignments /> },
        { path: "assets/maintenance", element: <AssetMaintenance /> },
        { path: "user/profile", element: <UserProfile /> },
        { path: "user/settings", element: <UserSettings /> },
        { path: "user/dashboard", element: <UserDashboard /> },
        { path: "notifications", element: <Notifications /> },
        { path: "admin/dashboard", element: <AdminDash /> },
        { path: "admin/credential", element: <AdminCredential /> },
        { path: "admin/category", element: <AdminCategory /> },
        { path: "admin/roles", element: <AdminRoles /> },
        { path: "admin/projects", element: <AdminProjects /> },
        { path: "admin/role/:id", element: <AdminRole /> },
        { path: "admin/credential/:id", element: <AdminCredentialView /> },
        { path: "admin/firm", element: <AdminFirm /> },
        { path: "admin/user", element: <AdminUser /> },
        { path: "firm/profile", element: <FirmProfile /> },
        { path: "users/list", element: <UsersList /> },
        { path: "team/onboarding/newUser", element: <UserOnboarding /> },
        { path: "team/onboarding/newFirm", element: <FirmOnboarding /> },
        { path: "team/onboarding/pending", element: <PendingOnboarding /> },
        { path: "team/onboarding/request/:id", element: <RoleRequest /> },
        { path: "team/onboarding/request-review/:id", element: <RoleRequestReview /> },
        { path: "team/onboarding/review", element: <ReviewOnboarding /> },
        { path: "users/rolemanagement", element: <UsersRoleManagement /> },
        { path: "events/personal", element: <UserCalendar /> },
        { path: "inspectiontypes/*", element: <Inspections /> },
        { path: "myInspections/*", element: <MyInspections /> },

        { path: "dataReplication", element: <DataReplicationRequests /> },

        { path: "dataReplication/results/:replicationRequestId", element: <DataReplicationRequestResults /> },
        { path: "inspections/addresses", element: <InspectionAddresses /> },
        {
          path: "environmentMapping/environmentMap",
          element: <EnvironmentMap />,
        },
        {
          path: "environmentMapping/geoRules",
          element: <GeoRules />,
        },
        { path: "inspections/create", element: <InspectionCreate /> },
        { path: "inspections/list", element: <InspectionList /> },
        { path: "inspections/assignments", element: <InspectionAssignments /> },
        { path: "inspections/schedule", element: <InspectionSchedule /> },
        {
          path: "inspections/inspection/inspectionEventLogs",
          element: <InspectionEventLogs />,
        },
        { path: "projects/list", element: <Projects /> },
        { path: "firm/firmUnits/list", element: <FirmUnits /> },
        { path: "admin/firms", element: <Firms /> },
        { path: "admin/people", element: <People /> },
        { path: "admin/projectpeople", element: <ProjectPeopleAdmin /> },
        { path: "admin/firmpeople", element: <FirmPeopleAdmin /> },
        { path: "admin/firmunitpeople", element: <FirmUnitPeopleAdmin /> },
        { path: "admin/projectfirmunit", element: <ProjectFirmUnitAdmin /> },
        { path: "admin/firmunits", element: <FirmUnitAdmin /> },
        { path: "admin/users", element: <Users /> },
        { path: "admin/firm/:id", element: <Firm /> },
        { path: "admin/firm-unit/:id", element: <FirmUnit /> },
        { path: "admin/project/:id", element: <AdminProject /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "onboarding/request/:id", element: <RoleRequestNonAuth /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/app" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
