import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  RadioGroup,
  Radio,
  Button,
  Stack,
  MenuItem,
  InputLabel,
  CircularProgress,
  Card,
  CardMedia,
  Skeleton,
  CardActionArea,
} from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Gesture, MapOutlined, Photo } from "@mui/icons-material";
import {
  TextField as TextFieldType,
  Field,
  ChecklistField,
  LongTextField,
  PossibleValue,
  OptionSelectField,
  DropDownField,
  YesNoField,
  PhoneField,
  EmailField,
  NumberField,
  DateField,
  DateTimeField,
  PhotoCollectionField,
  TimeField,
  SignatureField,
  YearField,
  MapCaptureField,
  DescriptionField,
} from "./TypeDefinitions";
import {
  IFormField,
  IFormJson,
  IFormValue,
} from "../../../Review/pages/InspectionAssignment/components/InspectionDisplay";
import { v4 as uuidv4 } from "uuid";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { current } from "immer";
import { C } from "@fullcalendar/core/internal-common";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import MuiMarkdown from "mui-markdown";

const PHOTOS_QUERY = gql`
  query Photos($photo: InputPhotoParams!) {
    photos(photo: $photo) {
      id
      capturedAt
      description
      image
      metadata
      referenceId
      referenceType
      title
      url
      isDeleted
      photoCollectionId
      photoCollectionTitle
      personId
      inspectionId
      inspectionAssignmentId
      thoFormId
      thoFormInstanceId
      thoFormFieldId
      thoFormFieldInstanceId
    }
  }
`;

const GET_PHOTO = gql`
  query Photo($photoId: ID!) {
    photo(id: $photoId) {
      id
      capturedAt
      description
      image
      metadata
      referenceId
      referenceType
      title
      url
      isDeleted
      photoCollectionId
      photoCollectionTitle
      personId
      inspectionId
      inspectionAssignmentId
      thoFormId
      thoFormInstanceId
      thoFormFieldId
      thoFormFieldInstanceId
    }
  }
`;

const UPLOAD_PHOTO = gql`
  mutation UploadPhoto($photo: InputPhotoParams) {
    uploadPhoto(photo: $photo) {
      id
    }
  }
`;

const DELETE_PHOTO = gql`
  mutation DeletePhoto($deletePhotoId: ID!) {
    deletePhoto(id: $deletePhotoId) {
      id
    }
  }
`;

interface FormStateHandler {
  completedData: IFormField;
  updateData: (data: IFormField) => void;
  formJson: IFormJson;
  firmId: string;
  personId: string;
}
interface ChildFieldProps {
  fieldData: Field;
  formState?: FormStateHandler;
  callback?: any;
}

export default function ChildField({
  fieldData,
  formState,
  callback,
}: ChildFieldProps) {
  const errorDiv =
    formState &&
    formState.completedData.formInput.errors &&
    formState.completedData.formInput.errors.length
      ? { borderStyle: "solid", borderColor: "red", borderRadius: 5 }
      : {};
  return (
    <div style={{ ...errorDiv }}>
      <ChildFieldWithoutError
        fieldData={fieldData}
        formState={formState}
        callback={callback}
      />
      {formState &&
        formState.completedData.formInput.errors &&
        formState.completedData.formInput.errors.map((err) => (
          <p style={{ color: "red", marginLeft: "0.5rem" }}>{err}</p>
        ))}
    </div>
  );
}

function ChildFieldWithoutError({
  fieldData,
  formState,
  callback,
}: ChildFieldProps) {
  switch (fieldData.inputType) {
    case "MapCapture":
      return (
        <FormMapCapture
          fieldData={fieldData}
          formState={formState}
          callback={callback}
        />
      );
    case "Text":
      return <FormShortText fieldData={fieldData} formState={formState} />;
    case "LongText":
      return <FormLongText fieldData={fieldData} formState={formState} />;
    case "Phone":
      return <FormPhone fieldData={fieldData} formState={formState} />;
    case "Checklist":
      return <FormChecklist fieldData={fieldData} formState={formState} />;
    case "OptionSelect":
      return <FormRadio fieldData={fieldData} formState={formState} />;
    case "DropDown":
      return <FormDropDown fieldData={fieldData} formState={formState} />;
    case "YesNo":
      return <YesNo fieldData={fieldData} formState={formState} />;
    case "Email":
      return <FormEmail fieldData={fieldData} formState={formState} />;
    case "Number":
      return <FormNumber fieldData={fieldData} formState={formState} />;
    case "Date":
      return <FormDate fieldData={fieldData} formState={formState} />;
    case "Year":
      return <FormYear fieldData={fieldData} formState={formState} />;
    case "DateTime":
      return <FormDateTime fieldData={fieldData} formState={formState} />;
    case "Time":
      return <FormTime fieldData={fieldData} />;
    case "PhotoCollection":
      return <FormPhoto fieldData={fieldData} formState={formState} />;
    case "Signature":
      return <FormSignature fieldData={fieldData} />;
    case "Description":
      return <FormDescription fieldData={fieldData} />;
    default:
      exhaustiveGuard(fieldData);
  }
}

// DONE!

const FormMapCapture: FC<{
  fieldData: MapCaptureField;
  formState?: FormStateHandler;
  callback?: any;
}> = ({ fieldData, formState, callback }) => {
  var fieldId = "mapCapture" + fieldData.id;
  async function capture() {
    let res = await callback();
    if (!formState) return;
    let fieldCopy: IFormField = JSON.parse(
      JSON.stringify(formState.completedData)
    );
    if (!fieldCopy.formInput.formValue) return;
    fieldCopy.formInput.formValue.jsonValue = { mapCapture: fieldId };
    formState.updateData(fieldCopy);
  }
  return (
    <div>
      <Button
        onClick={capture}
        endIcon={<MapOutlined />}
        variant="contained"
        sx={{ marginTop: "1rem" }}
      >
        Capture From Map
      </Button>
      <div id={fieldId}></div>
    </div>
  );
};

const FormYear: FC<{
  fieldData: YearField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState<string | null>("");

  const noErrorOnEmpty =
    value === ""
      ? {
          error: false,
        }
      : {};

  useEffect(() => {
    if (formState) {
      let prepopDateString =
        formState.completedData.formInput.formValue?.beginDateValue;
      if (!prepopDateString) return;
      let date = new Date(prepopDateString).toString();
      setValue(date);
    }
  }, [formState]);

  function handleChange(date: string) {
    if (formState) {
      const { completedData, formJson, firmId, personId, updateData } =
        formState;
      const dateObj = new Date(date);
      let dateString = dateObj.toISOString();

      let newFieldData = updatePrepopData(
        completedData,
        formJson,
        firmId,
        personId,
        {
          beginDateValue: dateString,
        }
      );

      updateData(newFieldData);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        views={["year"]}
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={(e) => {
          e && handleChange(e);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth
              {...noErrorOnEmpty}
              helperText={
                params.error ? (
                  <>
                    <>Please enter a valid year.</>
                    <br />
                    <span style={{ color: "grey" }}>{description}</span>
                  </>
                ) : (
                  description
                )
              }
              placeholder="mm/dd/yyyy"
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

const FormShortText: FC<{
  fieldData: TextFieldType;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description, placeholder } = fieldData;

  const updateField = (newValue: string) => {
    if (!formState) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };

  return (
    <TextField
      label={label}
      fullWidth
      helperText={description}
      placeholder={placeholder}
      onChange={(e) => updateField(e.target.value)}
      value={formState?.completedData.formInput.formValue?.textValue || ""}
    />
  );
};

const FormDescription: FC<{ fieldData: DescriptionField }> = ({
  fieldData,
}) => {
  const { description } = fieldData;
  return (
    <Stack direction="column">
      <MuiMarkdown>{description}</MuiMarkdown>
    </Stack>
  );
};

const FormSignature: FC<{ fieldData: SignatureField }> = ({ fieldData }) => {
  const { label, description } = fieldData;
  return (
    <Stack direction="column">
      <FormLabel>{label}</FormLabel>
      <FormHelperText>{description}</FormHelperText>
      <Button
        endIcon={<Gesture />}
        variant="contained"
        sx={{ marginTop: "1rem" }}
      >
        Capture Signature
      </Button>
    </Stack>
  );
};

const FormChecklist: FC<{
  fieldData: ChecklistField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { possibleValues, description, label } = fieldData;
  function convertPossibleValuesToObj(
    possibleValues: PossibleValue[],
    selectedValues: string[] | null
  ) {
    let obj: any = {};
    possibleValues?.forEach(function (value) {
      obj[value.id] = selectedValues && selectedValues.includes(value.label);
    });
    return obj;
  }

  const [checkedValues, setCheckedValues] = useState(
    convertPossibleValuesToObj(
      possibleValues,
      formState?.completedData.formInput.formValue?.textValues || null
    )
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedValues({
      ...checkedValues,
      [event.target.name]: !checkedValues[event.target.name],
    });
    if (formState) {
      let newCheckedValuesObj = {
        ...checkedValues,
        [event.target.name]: !checkedValues[event.target.name],
      };
      let valuesArray: string[] = [];
      for (const [key, value] of Object.entries(newCheckedValuesObj)) {
        if (value === true) {
          const textValue = possibleValues.find((pv) => pv.id === key)?.label;
          if (!textValue) return;
          valuesArray.push(textValue);
        }
      }
      let newField = updatePrepopData(
        formState.completedData,
        formState.formJson,
        formState.firmId,
        formState.personId,
        { textValues: valuesArray }
      );

      formState.updateData(newField);
    }
  };

  return (
    <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {possibleValues?.map(function (value) {
          return (
            <FormControlLabel
              key={value.id}
              control={
                <Checkbox
                  name={value.id}
                  checked={checkedValues[value.id]}
                  onChange={handleChange}
                />
              }
              label={value.label}
            />
          );
        })}
      </FormGroup>
      {description && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
};

const FormRadio: FC<{
  fieldData: OptionSelectField;
  formState?: FormStateHandler;
  isYesNo?: boolean;
}> = ({ fieldData, formState, isYesNo }) => {
  const { label, description, possibleValues } = fieldData;
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    const prefilledValue = () => {
      if (!formState) return null;
      const { completedData } = formState;
      if (!completedData.formInput.formValue) return null;
      let formValue = completedData.formInput.formValue;
      let selectedPossibleValue = fieldData.possibleValues.find((pv) =>
        isYesNo
          ? (pv.label === "Yes") === formValue.booleanValue
          : pv.label === formValue.textValue
      );
      if (!selectedPossibleValue) return null;
      return selectedPossibleValue.id;
    };
    if (formState && formState.completedData.formInput.formValue) {
      let resolvedValue = prefilledValue();
      if (resolvedValue) setSelected(resolvedValue);
    }
  }, [formState, fieldData.possibleValues, isYesNo]);

  const updateField = (newValue: string) => {
    let possibleValueSelected = fieldData.possibleValues.find(
      (pv) => pv.id === newValue
    );

    if (!formState || !possibleValueSelected) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      {
        ...(!isYesNo
          ? { textValue: possibleValueSelected.label }
          : {
              booleanValue: possibleValueSelected.label === "No" ? false : true,
            }),
      }
    );
    updateData(newFieldData);
  };

  return (
    <FormControl sx={{ marginLeft: "0.6rem" }}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        name={`${label} group`}
        value={selected}
        onChange={
          formState
            ? (e) => updateField(e.target.value)
            : (e) => setSelected(e.target.value)
        }
      >
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          {possibleValues.map((value) => (
            <FormControlLabel
              key={value.id}
              value={value.id}
              control={<Radio />}
              label={<div style={{ marginTop: "6px" }}>{value.label}</div>}
              disableTypography
              sx={{ alignItems: "start" }}
            />
          ))}
        </div>
      </RadioGroup>
      {description && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
};

const FormDropDown: FC<{
  fieldData: DropDownField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    const prefilledValue = () => {
      if (!formState) return null;
      const { completedData } = formState;
      if (!completedData.formInput.formValue) return null;
      let formValue = completedData.formInput.formValue;
      let selectedPossibleValue = fieldData.possibleValues.find(
        (pv) => pv.label === formValue.textValue
      );
      if (!selectedPossibleValue) return null;
      return selectedPossibleValue.id;
    };
    if (formState && formState.completedData.formInput.formValue) {
      let resolvedValue = prefilledValue();
      if (resolvedValue) setSelected(resolvedValue);
    }
  }, [formState, fieldData.possibleValues]);

  const updateField = (newValue: string) => {
    let possibleValueSelected = fieldData.possibleValues.find(
      (pv) => pv.id === newValue
    );

    if (!formState || !possibleValueSelected) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: possibleValueSelected.label }
    );
    updateData(newFieldData);
  };
  return (
    <TextField
      label={fieldData.label}
      fullWidth
      select
      helperText={fieldData.description}
      value={selected}
      onChange={
        formState
          ? (e) => updateField(e.target.value)
          : (e) => setSelected(e.target.value)
      }
    >
      {fieldData.possibleValues.map((possibleValue) => (
        <MenuItem key={possibleValue.id} value={possibleValue.id}>
          {possibleValue.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const YesNo: FC<{ fieldData: YesNoField; formState?: FormStateHandler }> = ({
  fieldData,
  formState,
}) => {
  let newFieldData: OptionSelectField = {
    ...fieldData,
    inputType: "OptionSelect",
    possibleValues: [],
  };
  newFieldData.possibleValues = [
    {
      id: "1",
      label: "Yes",
      value: "1",
      description: "",
      displayIndex: 0,
      formFieldId: fieldData.id,
      name: "yes",
    },
    {
      id: "2",
      label: "No",
      value: "2",
      description: "",
      displayIndex: 1,
      formFieldId: fieldData.id,
      name: "no",
    },
  ];

  return (
    <FormRadio fieldData={newFieldData} isYesNo={true} formState={formState} />
  );
};

const FormLongText: FC<{
  fieldData: LongTextField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description, placeholder } = fieldData;
  const updateField = (newValue: string) => {
    if (!formState) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };

  return (
    <TextField
      label={label}
      fullWidth
      helperText={description}
      placeholder={placeholder}
      onChange={(e) => updateField(e.target.value)}
      multiline
      value={formState?.completedData.formInput.formValue?.textValue || ""}
    />
  );
};

const FormPhone: FC<{
  fieldData: PhoneField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;
  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (
      formState &&
      formState.completedData.formInput.formValue &&
      formState.completedData.formInput.formValue.textValue
    ) {
      setState(formState.completedData.formInput.formValue.textValue);
    }
  }, [formState]);

  const updateField = (newValue: string) => {
    if (!formState) {
      setState(newValue);
      return;
    }

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };

  return (
    <ReactInputMask
      mask="999 - 999 - 9999"
      value={state || ""}
      onChange={(e) => updateField(e.target.value)}
      maskChar={null}
      disabled={false}
      placeholder="### - ### - ####"
    >
      <TextField fullWidth label={label} helperText={description} />
    </ReactInputMask>
  );
};

const FormEmail: FC<{
  fieldData: EmailField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;

  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (
      formState &&
      formState.completedData.formInput.formValue &&
      formState.completedData.formInput.formValue.textValue
    ) {
      setState(formState.completedData.formInput.formValue.textValue);
    }
  }, [formState]);

  const updateField = (newValue: string) => {
    if (!formState) {
      setState(newValue);
      return;
    }

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };
  return (
    <TextField
      label={label}
      fullWidth
      onChange={(e) => updateField(e.target.value)}
      value={state}
      helperText={description}
      placeholder="example@email.com"
    />
  );
};

const FormNumber: FC<{
  fieldData: NumberField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (formState) {
      if (formState.completedData.formInput.formValue) {
        let doubleValue: number | null =
          formState.completedData.formInput.formValue.doubleValue;
        doubleValue !== null && setValue(doubleValue.toString());
      }
    }
  }, [formState]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    // debugger;
    const onlyNums =
      e.target.value === "" ? "0" : e.target.value.replace(/[^0-9]/g, "");
    if (formState) {
      const { completedData, updateData, formJson, firmId, personId } =
        formState;
      const newData = updatePrepopData(
        completedData,
        formJson,
        firmId,
        personId,
        {
          doubleValue: Number(onlyNums),
        }
      );
      updateData(newData);
    } else {
      setValue(onlyNums);
    }
  }

  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={handleChange}
      helperText={
        <>
          {description && (
            <>
              {description}
              <br />
            </>
          )}
          <span style={{ fontWeight: "bold" }}>
            Only numbers in this field.
          </span>
        </>
      }
    />
  );
};

const FormDate: FC<{ fieldData: DateField; formState?: FormStateHandler }> = ({
  fieldData,
  formState,
}) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState<string | null>("");

  const noErrorOnEmpty = value === "" && {
    error: false,
  };

  useEffect(() => {
    if (formState) {
      let prepopDateString =
        formState.completedData.formInput.formValue?.beginDateValue;
      if (!prepopDateString) return;
      let date = new Date(prepopDateString).toString();
      setValue(date);
    }
  }, [formState]);

  function handleChange(date: string) {
    if (formState) {
      const { completedData, formJson, firmId, personId, updateData } =
        formState;
      const dateObj = new Date(date);
      let dateString = dateObj.toISOString();
      let newFieldData = updatePrepopData(
        completedData,
        formJson,
        firmId,
        personId,
        {
          beginDateValue: dateString,
        }
      );
      updateData(newFieldData);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={(e) => {
          e && handleChange(e);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            {...noErrorOnEmpty}
            helperText={
              params.error ? (
                <>
                  <>Please enter a valid date.</>
                  <br />
                  <span style={{ color: "grey" }}>{description}</span>
                </>
              ) : (
                description
              )
            }
            placeholder="mm/dd/yyyy"
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormDateTime: FC<{
  fieldData: DateTimeField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState<string | null>("");

  const noErrorOnEmpty = value === "" && {
    error: false,
  };

  useEffect(() => {
    if (formState) {
      let prepopDateString =
        formState.completedData.formInput.formValue?.beginDateValue;
      if (!prepopDateString) return;
      let date = new Date(prepopDateString).toString();
      setValue(date);
    }
  }, [formState]);

  function handleChange(date: string) {
    if (formState) {
      const { completedData, formJson, firmId, personId, updateData } =
        formState;
      const dateObj = new Date(date);
      let dateString = dateObj.toISOString();
      let newFieldData = updatePrepopData(
        completedData,
        formJson,
        firmId,
        personId,
        {
          beginDateValue: dateString,
        }
      );
      updateData(newFieldData);
    } else {
      setValue(date);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={(e) => e && handleChange(e)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            helperText={
              params.error ? (
                <>
                  <>Please enter a valid date and time.</>
                  <br />
                  <span style={{ color: "grey" }}>{description}</span>
                </>
              ) : (
                description
              )
            }
            {...noErrorOnEmpty}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormPhoto: FC<{
  fieldData: PhotoCollectionField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const [getPhotos, { data, loading, refetch }] = useLazyQuery(PHOTOS_QUERY);
  const [spinner, setSpinner] = useState(false);
  const [uploadPhoto, { data: uploadedFile, loading: uploading }] =
    useMutation(UPLOAD_PHOTO);

  useEffect(() => {
    if (
      !data &&
      !loading &&
      formState &&
      formState.completedData.formInput.formValue &&
      formState.completedData.formInput.formValue.photoIds &&
      formState.completedData.formInput.formValue.photoIds.length > 0
    ) {
      getPhotos({
        variables: {
          photo: {
            thoFormFieldInstanceId: formState.completedData.id,
          },
        },
      });
    }
  }, [formState, getPhotos, data, loading]);

  useEffect(() => {
    if (!formState) return;
    refetch({
      variables: {
        photo: {
          thoFormFieldInstanceId: formState.completedData.id,
        },
      },
    });
  }, [formState, refetch]);

  const uploadFunction = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!formState?.updateData) return;
    if (!e.target.files) return;
    setSpinner(true);
    let files = Array.from(e.target.files);
    let newPhotoIds: string[] = [];
    let failure: boolean = false;
    const promises = files.map(async (file) => {
      const id = uuidv4();
      newPhotoIds.push(id);
      const base64withPrefix: any = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file);
      });
      const base64 = base64withPrefix.split(",")[1];
      const date = new Date();
      const dateString = date.toISOString();
      const fakemetadata = {
        meta: "data",
      };
      const promise = uploadPhoto({
        variables: {
          photo: {
            photoCollectionTitle: file.name,
            referenceId: formState.completedData.id,
            referenceType: "thoFormFieldInstanceId",
            thoFormFieldId: fieldData.id,
            thoFormId: fieldData.formId,
            thoFormInstanceId: formState.completedData.formInstanceId,
            title: fieldData.label,
            thoFormFieldInstanceId: formState.completedData.id,
            photoCollectionId: id,
            personId: formState.personId,
            metadata: JSON.stringify(fakemetadata),
            inspectionId: formState.formJson.inspectionId,
            image: base64,
            id: id,
            description: "desc",
            capturedAt: dateString,
            inspectionAssignmentId: formState.formJson.inspectionAssignmentId,
            isDeleted: false,
          },
        },
      });
      e.target.value = "";
      return promise;
    });
    await Promise.all(promises)
      .then(() => {
        setSpinner(false);
      })
      .catch((e) => {
        failure = true;
      });
    if (!failure) {
      let newData = formState.completedData;
      const currentIds = formState.completedData.formInput.formValue
        ? formState.completedData.formInput.formValue.photoIds || []
        : [];
      const date = new Date();
      const dateString = date.toISOString();
      let newField = updatePrepopData(
        newData,
        formState.formJson,
        formState.firmId,
        formState.personId,
        {
          updatedAt: dateString,
          photoIds: [...currentIds, ...newPhotoIds],
        }
      );
      formState.updateData(newField);
    }
  };

  const handleDeletePhotoId = (photoId: string) => {
    if (
      !formState ||
      !formState.completedData.formInput.formValue ||
      !formState.completedData.formInput.formValue.photoIds
    )
      return;
    console.log({ photoId });
    let photoList = [
      ...formState.completedData.formInput.formValue.photoIds,
    ].filter((id) => id !== photoId);

    formState.updateData(
      updatePrepopData(
        formState.completedData,
        formState.formJson,
        formState.firmId,
        formState.firmId,
        { photoIds: photoList }
      )
    );
  };

  return (
    <>
      <InputLabel>{fieldData.label}</InputLabel>
      <Stack direction="column" gap={2}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="image-upload"
          multiple
          type="file"
          onChange={uploadFunction}
        />
        <label htmlFor="image-upload">
          <Button
            endIcon={spinner ? <CircularProgress /> : <Photo />}
            disabled={spinner}
            variant="contained"
            component="span"
          >
            Upload Photo
          </Button>
        </label>
        <Stack direction={"column"} spacing={2}>
          {data &&
            !loading &&
            data.photos.length > 0 &&
            data.photos.map((photo: any) => (
              <DisplayPhoto
                photoId={photo.id}
                photoBase64={photo.image}
                handleDeletePhotoId={() => handleDeletePhotoId(photo.id)}
              />
            ))}
        </Stack>
      </Stack>
    </>
  );
};

const DisplayPhoto = ({
  photoId,
  photoBase64,
  handleDeletePhotoId,
}: {
  photoId: string;
  photoBase64: string;
  handleDeletePhotoId: () => void;
}) => {
  // const { data, loading } = useQuery(GET_PHOTO, {
  //   variables: {
  //     photoId: photoId,
  //   },
  // });
  const src = "data:image/*;base64," + photoBase64;
  const [open, setOpen] = useState(false);
  const [deletePhoto] = useMutation(DELETE_PHOTO, {
    variables: { deletePhotoId: photoId },
    onCompleted: () => {
      handleDeletePhotoId();
    },
  });

  return (
    <>
      <Card>
        <CardActionArea onClick={(e) => setOpen(true)}>
          <CardMedia component={"img"} src={src} width={"100%"} alt="photo" />
        </CardActionArea>
      </Card>
      {open && (
        <div
          onClick={(e) => {
            setOpen(false);
          }}
          style={{
            height: "100%",
            zIndex: 99999,
            backgroundColor: "rgba(34,34,34,0.75)",
            position: "fixed",
            width: "100vw",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={src} height={"100%"} alt="fullscreen" />
          <div
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              margin: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={(e) => deletePhoto()}
            >
              Delete?
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                let data = src;
                let w = window.open("about:blank");
                let image = new Image();
                image.src = data;
                setTimeout(function () {
                  w!.document.getElementsByTagName("body")[0].innerHTML =
                    image.outerHTML;
                }, 0);
              }}
            >
              New Tab
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const FormTime: FC<{ fieldData: TimeField }> = ({ fieldData }) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState<string | null>("");

  const noErrorOnEmpty = value === "" && {
    error: false,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label={label}
        value={value}
        onChange={(e) => setValue(e)}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            helperText={
              params.error ? (
                <>
                  <>Please enter a valid time.</>
                  <br />
                  <span style={{ color: "grey" }}>{description}</span>
                </>
              ) : (
                description
              )
            }
            {...noErrorOnEmpty}
          />
        )}
      />
    </LocalizationProvider>
  );
};

function updatePrepopData(
  formField: IFormField,
  form: IFormJson,
  firmId: string,
  personId: string,
  updateKeyValues: Partial<IFormValue>
) {
  let formFieldClone: IFormField = JSON.parse(JSON.stringify(formField));
  if (!formField.formInput.formValue) {
    formFieldClone.formInput.formValue = generateFormValue(
      form,
      formField,
      firmId,
      personId
    );
  }
  Object.keys(updateKeyValues).map(
    (key) =>
      ((formFieldClone.formInput.formValue as any)[key] = (
        updateKeyValues as any
      )[key])
  );

  return formFieldClone;
}

function generateFormValue(
  form: IFormJson,
  field: IFormField,
  firmId: string,
  personId: string
): IFormValue {
  return {
    beginDateValue: null,
    booleanValue: null,
    createdAt: null,
    createdBy: personId,
    doubleValue: null,
    endDateValue: null,
    entityId: "646ac12c-0f3b-11ed-9668-2eed6cdb025f",
    firmId: firmId,
    formConfigurationId: form.formConfigurationId,
    formFieldId: field.formFieldId,
    formFieldInstanceId: field.formInput.formFieldInstanceId,
    formId: form.formId,
    formInstanceId: field.formInput.formFieldInstanceId,
    id: uuidv4(),
    inspectionAssignmentId: form.inspectionAssignmentId,
    inspectionId: form.inspectionId,
    inspectionTemplateId: form.inspectionTypeTemplateId,
    integerValue: null,
    isDeleted: false,
    isDirty: false,
    jsonValue: null,
    name: "",
    parentId: null,
    photoIds: null,
    referenceType: "HomeInspectionAnswer",
    textValue: null,
    textValues: null,
    valueType: null,
  };
}
