import { useContext, useState, useEffect } from "react";
import { ProjectContext } from "../../../../utils/contexts/project";
import { Marker } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";
import { useSnackbar } from "notistack";
// material
import { Grid, Typography, Container } from "@mui/material";
// components
import Page from "../../../../components/Page";
import Map from "./map";
import ResourceTimeline from "./resourceTimeline";
import Assignment from "./assignmentForm";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import InspectionLocations from "./table";
import EventDetail from "./eventDetail";
// ----------------------------------------------------------------------

const GET_INSPECTIONS = gql`
  query projectInspections($projectId: ID, $status: String) {
    projectInspections(projectId: $projectId, status: $status) {
      contactName
      contactPhone
      id
      inspectionAssignments {
        id
        personId
        status
        start
        end
        isDeleted
      }
      inspectionLocation
      inspectionType {
        id
        projectId
        name
        description
        isWeb
        isMobile
        requiresDrawing
      }
      inspectionTypeId
      number
      projectId
      status
    }
  }
`;

const GET_PERSONS = gql`
  query Persons($ids: [ID]) {
    personsByIds(ids: $ids) {
      id
      avatar
      email
      firmId
      firstName
      lastName
      mailingAddressId
      phone1
      phone2
      preferences
      userId
    }
  }
`;

const GET_PROJECT_PERSONS = gql`
  query ProjectPersons($projectId: ID) {
    projectPersons(projectId: $projectId) {
      id
      personId
      project {
        id
        name
      }
      fieldReady
    }
  }
`;
const GET_ROLES = gql`
  query draftRolesByEntityIds($entityIds: [ID]) {
    draftRolesByEntityIds(entityIds: $entityIds) {
      id
      entityId
      roleType {
        id
        name
      }
    }
  }
`;

const GET_FIELD_READY_ROLES = gql`
  query FieldReadyRoles($projectId: ID) {
    fieldReadyRoles(projectId: $projectId) {
      id
      roleId
      project {
        id
        name
      }
    }
  }
`;

const GET_PROJECT_LOCATIONS = gql`
  query ProjectLocations($ids: [ID]) {
    projectLocationsByIds(ids: $ids) {
      id
      city
      description
      mapboxId
      name
      owner
      parentId
      state
      street1
      street2
      street3
      tenant
      zip
      projectId
      lat
      lng
      neighborhood
      lis
    }
  }
`;

const applyToArray = (func, array) => func.apply(Math, array);

const createMarker = (location, assignmentLocation, onLocationClick) => {
  if (
    assignmentLocation &&
    assignmentLocation.id === location.id &&
    location.lng &&
    location.lat
  ) {
    return (
      <Marker
        key={location.id}
        onClick={(mapEvent) => {
          onLocationClick(mapEvent, location);
        }}
        longitude={location.lng}
        latitude={location.lat}
        color="#00349c"
        anchor="bottom"
      ></Marker>
    );
  } else
    return (
      <Marker
        key={location.id}
        onClick={(mapEvent) => {
          onLocationClick(mapEvent, location);
        }}
        longitude={location.lng}
        latitude={location.lat}
        color="#4f4f4f"
        anchor="bottom"
      ></Marker>
    );
};

export default function InspectionList() {
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  const [persons, setPersons] = useState([]);
  const [hoverOpen, setHoverOpen] = useState(false);
  const [hoverEvent, setHoverEvent] = useState(null);
  const [projectLocations, setProjectLocations] = useState([]);
  const [projectPersons, setProjectPersons] = useState([]);
  const [personsRoles, setPersonsRoles] = useState([]);
  const [fieldReadyRoles, setFieldReadyRoles] = useState([]);
  const [inspectors, setInspectors] = useState([]);
  const [viewState, setViewState] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5,
  });
  const [inspections, setInspections] = useState([]);
  const [inspectionLocations, setInspectionLocations] = useState([]);
  const [assignmentLocation, setAssignmentLocation] = useState({
    street1: "",
    city: "",
    state: "",
  });
  const [inspection] = useState({});
  const handleLocationClick = (mapEvent, location) => {
    setAssignmentLocation(location);
  };
  let events = [];
  let markers = [];
  let draftInspectionLocations = [];

  const [getPersons] = useLazyQuery(GET_PERSONS, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setPersons(response.personsByIds);
    },
  });
  const [getPersonsRoles] = useLazyQuery(GET_ROLES, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setPersonsRoles(response.draftRolesByEntityIds);
    },
    onError: (response) => {},
  });
  const [getFieldReadyRoles] = useLazyQuery(GET_FIELD_READY_ROLES, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      let fieldReadyRoles = response.fieldReadyRoles.map((fieldReadyRole) => fieldReadyRole?.roleId)
      setFieldReadyRoles(fieldReadyRoles);
    },
    onError: (response) => {
    },
  });
  const [getProjectLocations] = useLazyQuery(GET_PROJECT_LOCATIONS, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setProjectLocations(response.projectLocationsByIds);
    },
  });
  const [getProjectPersons] = useLazyQuery(GET_PROJECT_PERSONS, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      getPersons({
        variables: {
          ids: response.projectPersons.map((person) => person.personId),
        },
      });
      getPersonsRoles({
        variables: {
          entityIds: response.projectPersons.map((person) => person.personId),
        },
      });
      setProjectPersons(response.projectPersons);
    },
  });
  const [getInspections] = useLazyQuery(GET_INSPECTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      getProjectLocations({
        variables: {
          ids: response.projectInspections.map(
            (inspection) => inspection.inspectionLocation
          ),
        },
      });
      setInspections(response.projectInspections);
    },
  });

  const refetch = () => {
    getProjectPersons({
      variables: { projectId: project.id, fieldReady: true },
    });
    getFieldReadyRoles({ variables: { projectId: project.id } });
    getInspections({
      variables: { projectId: project.id, status: "Unscheduled" },
    });
  };

  useEffect(() => {
    if (project) {
      getProjectPersons({
        variables: { projectId: project.id, fieldReady: true },
      });
      debugger;
      getInspections({ variables: { projectId: project.id, status: "Unscheduled" } });
    }
  }, [project, getProjectPersons, getInspections]);
  useEffect(() => {
    const personHasFieldReadyRoles = (personRoles) => {
      let personRolesIds = personRoles.map((role) => role.roleType.id);
      return fieldReadyRoles.every((val) => personRolesIds.includes(val));
    };
    let merged = [];
    if (
      Array.isArray(persons) &&
      Array.isArray(personsRoles) &&
      Array.isArray(projectPersons)
    ) {
      for (let i = 0; i < projectPersons.length; i++) {
        console.log("FIELD READY ", personHasFieldReadyRoles(
          personsRoles.filter(
            (role) => role.entityId === projectPersons[i].personId
          )
        ))
        merged.push({
          ...persons.find(
            (itmInner) => itmInner.id === projectPersons[i].personId
          ),
          ...projectPersons[i],
          id: projectPersons[i].personId,
          fieldReady: true
        });
      }
    }
    let inspectors = merged.filter((person) => person.fieldReady)
    setInspectors(inspectors);
  }, [projectPersons, persons, personsRoles, fieldReadyRoles]);

  if (
    projectLocations &&
    projectLocations.length > 0 &&
    inspections &&
    inspections.length > 0
  ) {
    for (let i = 0; i < projectLocations.length; i++) {
        let matchingInspections = inspections.filter((itmInner) => itmInner.inspectionLocation === projectLocations[i].id);

        if (projectLocations[i].lat && projectLocations[i].lng && inspection) {
            matchingInspections.forEach((inspection) => {
                draftInspectionLocations.push({
                    value: projectLocations[i].id,
                    ...projectLocations[i],
                    ...inspection,
                    id: inspection.id
                })
            })
        }
    }
    if (
      inspectionLocations.length === 0 &&
      draftInspectionLocations.length > 0
    ) {
      setInspectionLocations(draftInspectionLocations);
    }
    for (let i = 0; i < inspections.length; i++) {
      for (let j = 0; j < inspections[i].inspectionAssignments.length; j++) {
        let iA = inspections[i].inspectionAssignments[j];
        console.log(iA.status)
        if(!iA.isDeleted && iA.status !== 'cancelled'){
          events.push({
            title: inspections[i].inspectionType.name,
            resourceId: iA.personId,
            start: iA.start,
            end: iA.end,
            locationId: inspections[i].inspectionLocation,
            inspectionId: inspections[i].id,
            inspectionAssignment: iA,
            inspection: inspections[i]
          });
        }
      }
    }
  }
  useEffect(() => {
    if (inspectionLocations.length > 0) {
      const pointsLong = inspectionLocations.map((point) => point.lng);
      const pointsLat = inspectionLocations.map((point) => point.lat);
      const cornersLongLat = [
        [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
        [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
      ];
      // Use WebMercatorViewport to get center longitude/latitude and zoom
      const viewport = new WebMercatorViewport({
        width: 800,
        height: 600,
      }).fitBounds(cornersLongLat, { padding: 200 }); // Can also use option: offset: [0, -100]
      const { longitude, latitude, zoom } = viewport;
      setViewState((prevState) => {
        return { ...prevState, longitude, latitude, zoom };
      });
    }
  }, [inspectionLocations]);
  markers = inspectionLocations.map((location) =>
    createMarker(location, assignmentLocation, handleLocationClick)
  );
  return (
    <Page title="Inspections | FINBACK670">
      <EventDetail
        handleClose={() => {
          setHoverOpen(false);
        }}
        hoverOpen={hoverOpen}
        hoverEvent={hoverEvent}
        inspections={inspections}
        inspectionLocations={draftInspectionLocations}
        inspectors={inspectors}
        events={events}
        snackbar={snackbar}
        refetch={refetch}
      />
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} spacing={4}>
            <InspectionLocations
              inspectionLocations={draftInspectionLocations}
              setInspectionLocations={setInspectionLocations}
              setAssignmentLocation={setAssignmentLocation}
            />
          </Grid>
          <Grid item container columns={12} spacing={4}>
            <Grid item xs={8}>
              <Typography variant="h4">Inspection Addresses</Typography>
              <Map
                viewState={viewState}
                setViewState={setViewState}
                markers={markers}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">Inspection Assignment</Typography>
              <Assignment
                snackbar={snackbar}
                inspectors={inspectors}
                inspection={inspection}
                assignmentLocation={assignmentLocation}
                refetch={refetch}
                events={events}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} spacing={4}>
            <ResourceTimeline
              events={events}
              inspectors={inspectors}
              setHoverOpen={setHoverOpen}
              setHoverEvent={setHoverEvent}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
