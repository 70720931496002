import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  ExitToApp,
  ArrowBack,
  ArrowForward,
  CheckCircle,
  Cancel,
  Close,
  Error,
  Undo,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  Grid,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { PersonContext } from "src/utils/contexts/person";
import { ProjectContext } from "src/utils/contexts/project";
import { FirmContext } from "src/utils/contexts/firm";
import { encode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/encode";
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
import { Icon } from "@iconify/react";
import { DataReplication } from "./replicate";

const DATA_REPLICATION_REQUESTS = gql`
  query DataReplicationRequests(
    $dataReplicationRequest: InputDataReplicationRequestParams!
  ) {
    dataReplicationRequests(dataReplicationRequest: $dataReplicationRequest) {
      id
      personId
      sourceInspectionAssignment
      destinationProjectLocations
      destinationProjectLocationsFormatted
      sourceLocationFormatted
      jobId
      jobStatus
      insertedAt
    }
  }
`;

const INCOMPLETE_DATA_REPLICATION_REQUESTS = gql`
  query DataReplicationRequestsIncomplete(
    $dataReplicationRequest: InputDataReplicationRequestParams!
  ) {
    dataReplicationRequestsIncomplete(
      dataReplicationRequest: $dataReplicationRequest
    ) {
      id
      personId
      sourceInspectionAssignment
      destinationProjectLocations
      destinationProjectLocationsFormatted
      sourceLocationFormatted
      jobId
      jobStatus
      insertedAt
    }
  }
`;


const ROLLBACK_DATA_REPLICATION_REQUESTS = gql`
  mutation RollbackReplication($dataReplicationRequest: InputDataReplicationRequestParams) {
  rollbackReplication(dataReplicationRequest: $dataReplicationRequest) {
    id
  }
}
`;
export const DataReplicationRequests = ({}: {}) => {
  const person: any = useContext(PersonContext);
  const project: any = useContext(ProjectContext);
  const firmCtx: any = useContext(FirmContext);
  const [replicationRequests, setReplicationRequests] = useState([]);
  const funRef = useRef<any>(null);
  const [showReplicateAddresses, setShowReplicateAddresses] = useState(false);
  const [
    dataReplicationRequestsQuery,
    { data: replicationRequestsData, loading: replicationRequestsLoading },
  ] = useLazyQuery(DATA_REPLICATION_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (results) => {
      if (results) {
        var requests: any = [...replicationRequests];
        results.dataReplicationRequests.forEach((e: any) => {
          var index = requests.indexOf(
            requests.filter((d: any) => d.id === e.id)[0]
          );
          if (index >= 0) {
            requests[index] = e;
          } else {
            requests.push(e);
          }
        });
        setReplicationRequests(requests);
      }
    },
  });

  const [rollbackReplication] = useMutation(ROLLBACK_DATA_REPLICATION_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (results) =>{
      debugger;
    },
    onError: (error)=>{
      debugger;
    }
  });

  const [
    dataReplicationRequestsIncompleteQuery,
    {
      data: replicationRequestsIncompleteData,
      loading: replicationRequestsIncompleteLoading,
    },
  ] = useLazyQuery(INCOMPLETE_DATA_REPLICATION_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (results) => {
      if (results) {
        var requests: any = [...replicationRequests];
        results.dataReplicationRequestsIncomplete.forEach((e: any) => {
          var index = requests.indexOf(
            requests.filter((d: any) => d.id === e.id)[0]
          );
          if (index >= 0) {
            requests[index] = e;
          } else {
            requests.push(e);
          }
        });
        setReplicationRequests([]);
        setReplicationRequests(requests);
      }
    },
  });

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  useEffect(() => {
    if (
      !initialDataLoaded &&
      !replicationRequestsData &&
      !replicationRequestsLoading &&
      person.person
    ) {
      setInitialDataLoaded(true);
      dataReplicationRequestsQuery({
        variables: {
          dataReplicationRequest: {
            personId: person.person.id,
          },
        },
      });
    }
  }, [person]);

  useEffect(() => {}, [replicationRequests]);

  useEffect(() => {
    funRef.current = setInterval(() => {
      reloadDataReplicationRequestsToWatch();
    }, 5000);
    return () => {
     clearInterval(funRef.current);
    };
  });

  const apiRef = useGridApiRef();

  function handleAddRequest() {
    setShowReplicateAddresses(true);
  }

  async function reloadDataReplicationRequestsToWatch() {
    if(!replicationRequestsIncompleteLoading){
    dataReplicationRequestsIncompleteQuery({
      variables: {
        dataReplicationRequest: {
          personId: person.person.id,
        },
      },
    });
  }
  }


  async function handleReplicationRollback(drrId:any) {
    rollbackReplication({
      variables: {
        dataReplicationRequest: {
          id: drrId
        }
      }
    })
  }
  function NoRowsOverlay() {
    return (
      <Stack height="100px" alignItems="center" justifyContent="center">
        No Data Replication Requests found. Click "Add New" to submit a new Data Replication Request.
      </Stack>
    );
  }

  function getStatusIcon(status: any){
    var icon = <CircularProgress></CircularProgress>;
    switch (status) {
      case "completed":
        icon = (
          <CheckCircle sx={{ color: "green" }}></CheckCircle>
        );
        break;
      case "executing":
        icon = <CircularProgress></CircularProgress>;
        break;
      case "map gen incomplete":
        icon = <CircularProgress></CircularProgress>;
        break;
        case "report gen incomplete":
          icon = <CircularProgress></CircularProgress>;
          break;
      case "rollback":
        icon = <Undo></Undo>
        break;
      default:
        icon = <Error sx={{ color: "red" }}></Error>;
    }
    if(status.includes("map gen errors")){
      icon = <Warning sx={{color: "orange"}}></Warning>;
    }
    if(status.includes("report gen errors")){
      icon = <Warning sx={{color: "orange"}}></Warning>;
    }
    return icon;
  }

  return (
    <>
      <Typography variant="h3">Data Replication</Typography>
      {!initialDataLoaded && replicationRequestsLoading && <CircularProgress />}
      <Stack>
        {initialDataLoaded && !replicationRequestsLoading && (
          <Paper elevation={6}>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h4">Data Replication Requests</Typography>
              <Button
                onClick={handleAddRequest}
                variant="outlined"
                startIcon={<Icon icon={plusSquareOutline} />}
              >
                Add New
              </Button>
              <DataGridPremium
                sx={{
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                      display: "none",
                    },
                }}
                slots={{ noRowsOverlay: NoRowsOverlay }}
                rows={replicationRequests}
                apiRef={apiRef}
                columnVisibilityModel={{
                  id: false,
                }}
                autoHeight
                disableRowSelectionOnClick
                getRowHeight={() => "auto"}
                columns={[
                  { field: "id", headerName: "Id" },
                  {
                    field: "sourceLocationFormatted",
                    headerName: "Source Address",
                    width: 450,
                  },
                  {
                    field: "destinationProjectLocationsFormatted",
                    headerName: "Destination Addresses",
                    width: 450,
                    renderCell: (params: any) => {
                      var listItems = params.value.map((e: any) => {
                        return <ListItem>{e}</ListItem>;
                      });
                      return <List>{listItems}</List>;
                    },
                  },
                  {
                    field: "jobStatus",
                    headerName: "Status",
                    width: 200,
                    renderCell: (params: any) => {
                      var icon:any = getStatusIcon(params.value);
                      return <Box>{icon}</Box>;
                    },
                  },
                  {
                    field: "view",
                    headerName: "",
                    width: 10,

                    renderCell: (params: any) => {
                      return params.row.jobStatus === "completed" ? (
                        <Box>
                          <Link
                            to={`/app/dataReplication/results/${params.row.id}`}
                          >
                            <ExitToApp />
                          </Link>
                        </Box>
                      ) : (
                        <Box></Box>
                      );
                    },
                  },
                  {
                    field: "rollback",
                    headerName: "",

                    renderCell: (params: any) => {
                      return params.row.jobStatus !== "rollback" ? (
                        <Box>
                          <Tooltip title="Rollback Replication Changes">
                          <IconButton onClick={()=>{handleReplicationRollback(params.row.id);}} >
                          <Undo></Undo></IconButton></Tooltip>
                        </Box>
                      ) : (
                        <Box></Box>
                      );
                    },
                  },
                ]}
              />
            </Box>
          </Paper>
        )}
        {showReplicateAddresses && (
          <Dialog open={showReplicateAddresses} maxWidth="lg">
            <Grid container spacing={2} columns={12} sx={{ padding: "15px" }}>
              <Grid item xs={10}>
                <DialogTitle>Replicate Inspection Data</DialogTitle>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "15px",
                }}
              >
                <IconButton
                  autoFocus
                  onClick={() => {
                    setShowReplicateAddresses(false);
                  }}
                  color="primary"
                  sx={{
                    backgroundColor: "lightgray",
                    color: "gray",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent>
              <DataReplication
                initialStep={1}
                onComplete={(result: any) => {
                  setShowReplicateAddresses(false);
                  reloadDataReplicationRequestsToWatch();
                }}
              ></DataReplication>
            </DialogContent>
          </Dialog>
        )}
      </Stack>
    </>
  );
};
