import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PDFDocument } from 'pdf-lib'; 

const REPORT_TEMPLATES_QUERY = gql`
  query ReportTypeTemplates(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplates(reportTypeTemplate: $reportTypeTemplate) {
      id
      reportType {
        type
      }
      templateUrl
      configuration
    }
  }
`;

function base64ToUint8Array(base64) {
  const binaryString = atob(base64.split(',')[1]); // Remove the base64 prefix ('data:image/png;base64,') if present
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

function fieldExists(form, fieldName) {
  // Retrieve all form fields
  const fields = form.getFields();

  // Check if any field matches the given name
  const field = fields.find(f => f.getName() === fieldName);

  // Return true if field exists, false otherwise
  return field !== undefined;
}

async function fetchPdfAndFillForm(pdfUrl, fieldValues) {

  if ('undefined' in fieldValues) {
    delete fieldValues['undefined'];
  }

  // Fetch the PDF document from the URL
  const response = await fetch(pdfUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch PDF from ${pdfUrl}: ${response.statusText}`);
  }
  const pdfBuffer = await response.arrayBuffer();

  // Load the PDFDocument
  const pdfDoc = await PDFDocument.load(pdfBuffer);

  // The rest of the code is similar to the `fillPdfForm` function
  const form = pdfDoc.getForm();
  Object.keys(fieldValues).forEach(async fieldName => {
    const fieldValue = fieldValues[fieldName];
    const fieldDoesExist = fieldExists(form, fieldName)


    if (fieldDoesExist) {
      const field = form.getField(fieldName);
      if (typeof fieldValue === 'boolean') {
        fieldValue ? field.check() : field.uncheck();
      } else if (fieldName === 'map') {
        const imageBytes = base64ToUint8Array(fieldValue);
        const embeddedImage = await pdfDoc.embedPng(imageBytes); // Use embedJpg for JPEG images
        const page = pdfDoc.addPage([612, 792]);
        // Calculate image dimensions to maintain aspect ratio and fit within the A4 page
        const imageAspectRatio = embeddedImage.width / embeddedImage.height;
        let imageWidth = page.getWidth();
        let imageHeight = imageWidth / imageAspectRatio;
        if (imageHeight > page.getHeight()) {
          imageHeight = page.getHeight();
          imageWidth = imageHeight * imageAspectRatio;
        }

        // Draw the image on the new page, centered
        page.drawImage(embeddedImage, {
          x: (page.getWidth() - imageWidth) / 2,
          y: (page.getHeight() - imageHeight) / 2,
          width: imageWidth,
          height: imageHeight,
        });
      } else {
        fieldValue && field.setText(fieldValue.toString());
      }
    } else if (fieldName === 'map') {
      const imageBytes = base64ToUint8Array(fieldValue);
      const embeddedImage = await pdfDoc.embedPng(imageBytes); // Use embedJpg for JPEG images
      const page = pdfDoc.addPage([612, 792]);
      // Calculate image dimensions to maintain aspect ratio and fit within the A4 page
      const imageAspectRatio = embeddedImage.width / embeddedImage.height;
      let imageWidth = page.getWidth();
      let imageHeight = imageWidth / imageAspectRatio;
      if (imageHeight > page.getHeight()) {
        imageHeight = page.getHeight();
        imageWidth = imageHeight * imageAspectRatio;
      }

      // Draw the image on the new page, centered
      page.drawImage(embeddedImage, {
        x: (page.getWidth() - imageWidth) / 2,
        y: (page.getHeight() - imageHeight) / 2,
        width: imageWidth,
        height: imageHeight,
      });
    }
  });

  form.flatten();
  return await pdfDoc.save();
}

export const handleDownload = (url, filename, configuration, formState, mapCaptures) => {
  const pdfTags = preparePdfTags(configuration, formState, mapCaptures);
  fetchPdfAndFillForm(url, pdfTags)
    .then(filledPdfBytes => {
      // Convert the filled PDF bytes to a Blob
      const blob = new Blob([filledPdfBytes], { type: 'application/pdf' });

      // Create a URL for the Blob
      const downloadUrl = URL.createObjectURL(blob);

      // Create an anchor tag to trigger the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename; // Set the filename for the downloaded PDF

      // Append the link to the document, trigger the click, and then remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(downloadUrl);
    })
    .catch(console.error);
};

// const DownloadAllButton = ({ pdfs }) => {
//   const downloadAllAsZip = async () => {
//     const zip = new JSZip();

//     // Use Promise.all to wait for all PDFs to be fetched and added to the zip
//     await Promise.all(pdfs.map(async (pdf) => {
//       const response = await fetch(pdf.templateUrl);
//       const blob = await response.blob();
//       zip.file(`${pdf.reportType.type}.pdf`, blob);
//     }));

//     // Generate the ZIP file and trigger the download
//     zip.generateAsync({ type: 'blob' }).then((content) => {
//       saveAs(content, 'hud-heros-worksheets.zip');
//     });
//   };

//   return (
//     <Button variant="contained" onClick={downloadAllAsZip}>
//       Download All PDFs
//     </Button>
//   );
// };
const preparePdfTags = (configuration, formState, mapCaptures) => {
  var templateId = Object.keys(configuration)[0];
  debugger;
  var formInstance = formState.formInstances.find((formInstance) => formInstance.formId === templateId);
  if(!formInstance){
    return null;
  }
  var fields = formInstance.fields;
  var configFields = configuration[templateId].fields

  var pdfTags = {}
  fields.forEach((field) => {
    let configField = configFields[field.formFieldId];
    switch (field.inputType) {
      case ("YesNo"):
        if (field.formInput.formValue.booleanValue) {
          if(configField){
            pdfTags[configField["yes"]] = true;
          }
        } else {
          if(configField){
            pdfTags[configField["no"]] = true;
          }
        }
        break;
      case ("OptionSelect"):
        if(configField){
          let osValue = configField[field.formInput.formValue.textValue];
          let osKeyValue = {osValue: true};
          pdfTags[osValue] = true;
        }
        break;
      case ("LongText"):
        if(configField){
          pdfTags[configField['longText']] = field.formInput.formValue.textValue;
        }
        break;
      case ("MapCapture"):
        
        if (field.formInput.formValue?.jsonValue?.mapCaptureId){
          let mc = mapCaptures.find((mapCapture) => mapCapture.id === field.formInput.formValue.jsonValue.mapCaptureId);
          if(mc){
            pdfTags["map"] = mc.image;
          }
        }
        break;
      default:
        break;
    }
  })
  return pdfTags
}
const preparePdfTagContent = (configuration, formState, mapCaptures) => {
  var templateId = Object.keys(configuration)[0];
  var formInstance = formState.formInstance;

  if(!formInstance){
    return null;
  }
  var fields = formInstance.fields;
  var configFields = configuration[templateId].fields

  var pdfTags = {}
  fields.forEach((field) => {
    let configField = configFields[field.formFieldId];
    switch (field.inputType) {
      case ("YesNo"):
        if (field.formInput.formValue.booleanValue) {
          if(configField){
            pdfTags[configField["yes"]] = true;
          }
        } else {
          if(configField){
            pdfTags[configField["no"]] = true;
          }
        }
        break;
      case ("OptionSelect"):
        if(configField){
          let osValue = configField[field.formInput.formValue.textValue];
          let osKeyValue = {osValue: true};
          pdfTags[osValue] = true;
        }
        break;
      case ("LongText"):
        if(configField){
          pdfTags[configField['longText']] = field.formInput.formValue.textValue;
        }
        break;
      case ("MapCapture"):
        
        if (field.formInput.formValue?.jsonValue?.mapCaptureId){
          let mc = mapCaptures.find((mapCapture) => mapCapture.id === field.formInput.formValue.jsonValue.mapCaptureId);
          if(mc){
            pdfTags["map"] = mc.image;
          }
        }
        break;
      default:
        break;
    }
  })
  return pdfTags
}

export const genPDFs = async (currZip, pdfs, formState, mapCaptures, address)=>{
  let zip = currZip ? currZip : new JSZip();
  let subfolder = zip.folder(address);
  // Use Promise.all to wait for all PDFs to be filled and added to the zip
  await Promise.all(pdfs.map(async (pdf) => {
    // Handle the filling of each PDF
    const filename = `${pdf.reportType.type}.pdf`;
    try {
      // Prepare the PDF tags for filling
      const pdfTags = preparePdfTags(pdf.configuration, formState, mapCaptures);
      // Fetch, fill, and return the filled PDF bytes
      const filledPdfBytes = await fetchPdfAndFillForm(pdf.templateUrl, pdfTags);
      
      debugger;
      // Add filled PDF to the ZIP
      subfolder.file(filename, filledPdfBytes);
    } catch (error) {
      console.error(`Error processing ${filename}:`, error);
    }
  }));

  return zip;
}

export function genPDFDownload(foldersToDownload){
  let zip = new JSZip();
  foldersToDownload.forEach((folder)=>{
    let subfolder = zip.folder(folder.address);
    folder.pdfs.forEach((pdf)=>{
      subfolder.file(pdf.fileName, pdf.bytes);
    });
  });
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'filled-reports.zip');
  });
}

export const genPDFContent = async (pdf, formState, mapCaptures)=>{
    // Handle the filling of each PDF
    const filename = `${pdf.reportType.type}.pdf`;
    try {
      // Prepare the PDF tags for filling
      const pdfTags = preparePdfTagContent(pdf.configuration, formState, mapCaptures);
      // Fetch, fill, and return the filled PDF bytes
      var filledPdfBytes = [];
      if(pdfTags){
        filledPdfBytes = await fetchPdfAndFillForm(pdf.templateUrl, pdfTags);
        return {
          formState: formState, 
          pdfBytes: filledPdfBytes, 
          reportTemplateId: pdf.id, 
          filename: filename
        };
      }
    } catch (error) {
      console.error(`Error processing ${filename}:`, error);
    }

  return null;
}

export const downloadZip = async (zip) => {

  // Generate the ZIP file and trigger the download
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'filled-reports.zip');
  });
}

export const downloadAllAsZip = async (pdfs, formState, mapCaptures, address) => {
  const zip = new JSZip();
  
  // Use Promise.all to wait for all PDFs to be filled and added to the zip
  await Promise.all(pdfs.map(async (pdf) => {
    // Handle the filling of each PDF
    const filename = `${pdf.reportType.type}.pdf`;
    try {
      // Prepare the PDF tags for filling
      const pdfTags = preparePdfTags(pdf.configuration, formState, mapCaptures);
      // Fetch, fill, and return the filled PDF bytes
      const filledPdfBytes = await fetchPdfAndFillForm(pdf.templateUrl, pdfTags);
      // Add filled PDF to the ZIP
      zip.file(filename, filledPdfBytes);
    } catch (error) {
      console.error(`Error processing ${filename}:`, error);
    }
  }));

  // Generate the ZIP file and trigger the download
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, address ? address.replace(' ', '_') + '_filled-reports.zip' : 'filled-reports.zip');
  });
};

const DownloadAllButton = ({ pdfs, formState, mapCaptures }) => {

  return (
    <Button variant="contained" onClick={() => { downloadAllAsZip(pdfs, formState, mapCaptures, null) }}>
      Download All PDFs
    </Button>
  );
};

export default function DownloadReport({ formState, mapCaptures }) {
  const [reportTypeTemplates, setReportTypeTemplates] = useState([]);
  const [selectedReports, setSelectedReports] = useState({});
  const [getReportTypeTemplates, { called, data }] = useLazyQuery(REPORT_TEMPLATES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const handleSelectReport = (id, isSelected) => {
    setSelectedReports((prevSelectedReports) => ({
      ...prevSelectedReports,
      [id]: isSelected,
    }));
  };

  const handleDownloadSelected = () => {
    const selectedPdfs = reportTypeTemplates.filter((pdf) => selectedReports[pdf.id]);
    downloadAllAsZip(selectedPdfs, formState, mapCaptures, null);
  };

  const selectAllReports = () => {
    const allSelected = reportTypeTemplates.reduce((acc, report) => {
      acc[report.id] = true;
      return acc;
    }, {});
    setSelectedReports(allSelected);
  };

  const unselectAllReports = () => {
    setSelectedReports({});
  };

  useEffect(() => {
    const inspectionTypeTemplate = formState?.inspection?.inspectionType?.inspectionTypeTemplates?.[0];

    const inspectionTypeTemplateId = inspectionTypeTemplate ? inspectionTypeTemplate.id : null;

    if (inspectionTypeTemplateId && !called) {
      getReportTypeTemplates({ variables: { reportTypeTemplate: { inspectionTypeTemplateId } } });
    }
  }, [formState, getReportTypeTemplates, called]);

  useEffect(() => {
    if (data) {
      setReportTypeTemplates(data.reportTypeTemplates);
    }
  }, [data]);


  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <Button variant="contained" onClick={selectAllReports} style={{ marginRight: '10px' }}>
            Select All
          </Button>
          <Button variant="contained" onClick={unselectAllReports}>
            Unselect All
          </Button>
        </div>
        <Button variant="contained" onClick={handleDownloadSelected}>
          Download Selected PDFs
        </Button>
      </div>
      <List>
        {reportTypeTemplates.map((pdf) => (
          <ListItem key={pdf.id} divider>
            <Checkbox
              checked={!!selectedReports[pdf.id]}
              onChange={(event) => handleSelectReport(pdf.id, event.target.checked)}
              inputProps={{ 'aria-label': 'select report' }}
            />
            <ListItemText primary={pdf.reportType.type} />
            <IconButton edge="end" aria-label="download" onClick={() => handleDownload(pdf.templateUrl, `${pdf.reportType.type}.pdf`, pdf.configuration, formState, mapCaptures)}>
              <DownloadIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </>
  );

}