import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Configuration,
  Expression,
  ValidationTrigger,
} from "src/pages/Inspections/FormBuilder/components/TypeDefinitions";
import {
  IFormField,
  IFormInstance,
  IFormJson,
  IFormValue,
  ITopLevelFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import { useImmerReducer } from "use-immer";
import {
  ExpressionFieldToCompare,
  FormMap,
  FormMapWithOrder,
  FormInstanceMap,
  getFormsIndex,
  getFormInstancesIndex,
} from "../wrapper";
import { decode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/decode";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
// import { encode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/encode";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import { encode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/encode";
import { PersonContext } from "src/utils/contexts/person";
import { VariantType, useSnackbar } from "notistack";
import { breakTextIntoLines } from "pdf-lib";
// import { current } from "immer";
import { v4 as uuidv4 } from "uuid";
import { FirmContext } from "src/utils/contexts/firm";
import { func } from "prop-types";
import Expressions from "src/pages/Inspections/FormBuilder/components/Expressions";
import useDebounce from "src/utils/useDebounce";
import { SyncHandler } from "src/pages/Inspections/FormBuilder/FormWrapper/SyncHandler";
import { E } from "@fullcalendar/resource/internal-common";
import { current } from "immer";
import { ProjectContext } from "src/utils/contexts/project";

const UPLOAD_FORM_INSTANCE = gql`
  mutation UploadThoFormInstance($thoFormInstance: InputThoFormInstanceParams) {
    uploadThoFormInstance(thoFormInstance: $thoFormInstance) {
      id
      name
    }
  }
`;

const SET_STATUS = gql`
  mutation UploadInspectionStatus(
    $inspectionStatus: InputInspectionStatusParams
    $inspectionAssignmentStatus: InputInspectionAssignmentStatusParams
  ) {
    uploadInspectionStatus(inspectionStatus: $inspectionStatus) {
      id
    }
    uploadInspectionAssignmentStatus(
      inspectionAssignmentStatus: $inspectionAssignmentStatus
    ) {
      id
    }
  }
`;

type Action =
  | {
      type: "updateField";
      payload: {
        thoFormIndex: number;
        subformIndexPath: number[];
        fieldIndex: number;
        newField: IFormField;
      };
    }
  | {
      type: "clearAfterSync";
      payload: {
        type: "onsiteComplete" | "formSave" | "message";
      };
    }
  | {
      type: "submit";
      payload: {
        thoFormIndex: number;
        subformIndexPath: number[];
      };
    }
  | {
      type: "updateValidationsNeedingRequest";
      payload: {
        expressionsNeedingRequest: ExpressionNeedingRequest[];
      };
    }
  | {
      type: "updateInitialMapCapturesGenerated";
    }
  | {
      type: "initialize";
    }
  | {
      type: "onsiteComplete";
    }
  | { type: "REPLACE_STATE_DO_NOT_CALL"; payload: State };

export type State = {
  formMap: FormMapWithOrder;
  formInstanceMap: FormInstanceMap;
  configuration: Configuration;
  formInstances: IFormJson[];
  inspection: any;
  inspectionAssignment: any;
  thoFormIndexesRequiringSync: number[];
  onsiteCompleteSync: boolean;
  message: { text: string; style: VariantType } | null;
  firmId: string;
  personId: string;
  expressionsNeedingRequest: ExpressionNeedingRequest[] | null;
  mapCapturesNeedingGeneration: MapCapturesNeedingGeneration[] | null;
  //callback: Function | null;
};

type ExpressionNeedingRequest = {
  id: string;
  fieldId: string;
  formId: string;
  validationId: string;
  expressionId: string;
  inspectionId: string;
  geoRuleId: string;
  value: any;
};

type MapCapturesNeedingGeneration = {
  formInstance: any;
};

export const WebInspectionCtx = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
  //   @ts-ignore
}>(null);
const Controller = ({
  inspection,
  inspectionAssignment,
  formInstances,
  formConfig,
  children,
}: {
  inspection: any;
  inspectionAssignment: any;
  formInstances: IFormInstance[];
  formConfig: Configuration;
  children: ReactElement;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message: any, variant: any) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const [processedTemplate, setProcessedTemplate] = useState(false);

  const [thoFormInstanceUpdate] = useMutation(UPLOAD_FORM_INSTANCE);

  const { person, setPerson }: any = useContext(PersonContext);

  const { project, setProject }: any = useContext(ProjectContext);

  const firmCtx: any = useContext(FirmContext);

  const [updateStatus] = useMutation(SET_STATUS, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  function drill(form: IFormJson, subfieldIndexPath: number[]): IFormJson {
    if (subfieldIndexPath.length === 0) {
      return form;
    } else {
      const [first, ...rest] = subfieldIndexPath;
      return drill(form.subFormInstances[first], rest);
    }
  }

  function handleSubmit(draft: State, action: Action) {
    if (action.type !== "submit") {
      return;
    }
    const { subformIndexPath, thoFormIndex } = action.payload;
    const formJsonToUpdate = drill(
      draft.formInstances[thoFormIndex],
      subformIndexPath
    );
    for (
      let i = 0;
      i <
      draft.formMap.formMap[formJsonToUpdate.formId].form.validations.length;
      i++
    ) {
      if (
        draft.formMap.formMap[formJsonToUpdate.formId].form.validations[i]
          .onTrigger !== "FormCreate"
      )
        runValidation(formJsonToUpdate, i, draft);
    }
    let result = validateForm(formJsonToUpdate, draft, "FormSave");
    snackbar(
      result ? "Form Save Successful" : "Form is Invalid",
      result ? "success" : "error"
    );
    draft.thoFormIndexesRequiringSync.push(thoFormIndex);
  }

  function handleUpdateField(draft: State, action: Action) {
    if (action.type !== "updateField") {
      return;
    }
    const { fieldIndex, newField, subformIndexPath, thoFormIndex } =
      action.payload;
    const formJsonToUpdate = drill(
      draft.formInstances[thoFormIndex],
      subformIndexPath
    );
    let newErrors = newField.formInput.errors.filter(
      (error) => error !== "This field is required."
    );

    newField.formInput.errors = newErrors;
    newField.formInput.isValid = newErrors.length === 0;
    formJsonToUpdate.fields[fieldIndex] = newField;

    let expressionsEffected =
      draft.formMap.formMap[newField.formId].expressionsMap
        .expressionFieldToCompare[newField.formFieldId] ?? [];

    let orderedExpressionsEffected = [
      ...expressionsEffected.filter((exp) => exp.validationAction === "Show"),
      ...expressionsEffected.filter((exp) => exp.validationAction === "Error"),
      ...expressionsEffected.filter((exp) => exp.validationAction === "Answer"),
    ];

    runValidationsForExpressions(
      formJsonToUpdate,
      orderedExpressionsEffected,
      draft
    );
    if (!draft.thoFormIndexesRequiringSync.includes(thoFormIndex)) {
      draft.thoFormIndexesRequiringSync.push(thoFormIndex);
    }
  }

  function handleOnsiteComplete(draft: State, action: Action) {
    if (action.type !== "onsiteComplete") {
      return;
    }
    debugger;
    // HANDLE ONSITE COMPLETE
    let onsiteSuccess = true;
    let instancesToSync = [...draft.thoFormIndexesRequiringSync];
    for (let i = 0; i < draft.formInstances.length; i++) {
      let fi = draft.formInstances[i];
      if (!instancesToSync.includes(i)) {
        instancesToSync.push(i);
      }
      let formValid = validateForm(fi, draft, "FormSave");
      if (!formValid) {
        onsiteSuccess = false;
      }
    }
    draft.formInstances.forEach((fi, i) => {});
    draft.thoFormIndexesRequiringSync = instancesToSync;
    if (onsiteSuccess) {
      draft.onsiteCompleteSync = true;
    } else {
      draft.message = { text: "Onsite Complete Failed", style: "error" };
    }
  }

  function handleInitialize(draft: State, action: Action) {
    if (action.type !== "initialize") {
      return;
    }
    if (!draft.expressionsNeedingRequest) {
    }
    draft.formInstances.forEach((instance) => {
      initialize(instance, draft, person, project);
    });
    // for(let j = 0; j < draft.formInstances.length; j++){
    //   let instance = draft.formInstances[j];
    //   if (instance.needsInitialValidations) {
    //     instance.needsInitialValidations = false;
    //     validateForm(instance, draft, "FormCreate");
    //   }
    // }
    draft.thoFormIndexesRequiringSync = draft.formInstances.map((fi, i) => i);
  }

  function handleClearAfterSync(draft: State, action: Action) {
    if (action.type !== "clearAfterSync") {
      return;
    }
    switch (action.payload.type) {
      case "formSave":
        draft.thoFormIndexesRequiringSync = [];
        break;
      case "onsiteComplete":
        draft.onsiteCompleteSync = false;
        break;
      case "message":
        draft.message = null;
        break;
      default:
        exhaustiveGuard(action.payload.type);
    }
  }

  function handleUpdateValidationsNeedingRequest(draft: State, action: Action) {
    if (action.type !== "updateValidationsNeedingRequest") {
      return;
    }

    draft.expressionsNeedingRequest = action.payload.expressionsNeedingRequest;
    for (var i = 0; i < draft.formInstances.length; i++) {
      var instance = draft.formInstances[i];
      if (instance.needsInitialValidations) {
        var formJsonToUpdate = drill(draft.formInstances[i], []);

        validateForm(formJsonToUpdate, draft, "FormCreate");
      }
    }
  }

  function handleUpdateInitialMapCapturesGenerated(
    draft: State,
    action: Action
  ) {
    if (action.type !== "updateInitialMapCapturesGenerated") {
      return;
    }
    for (var i = 0; i < draft.formInstances.length; i++) {
        var formJsonToUpdate = drill(draft.formInstances[i], []);

        setInitialMapCaptureGenerated(formJsonToUpdate);
    }
  }

  function setInitialMapCaptureGenerated(formJson: IFormJson) {
    formJson.initialMapGenerated = true;
    if (formJson.subFormInstances != null) {
      formJson.subFormInstances.forEach((sf) => {
        setInitialMapCaptureGenerated(sf);
      });
    }
  }

  function handleREPLACE_STATE_DO_NOT_CALL(draft: State, action: Action) {
    if (action.type !== "REPLACE_STATE_DO_NOT_CALL") {
      return;
    }
    return JSON.parse(JSON.stringify(action.payload));
  }

  function handleAction(draft: State, action: Action) {
    switch (action.type) {
      case "submit":
        handleSubmit(draft, action);
        break;
      case "updateField":
        handleUpdateField(draft, action);
        break;
      case "onsiteComplete":
        handleOnsiteComplete(draft, action);
        break;
      case "initialize":
        handleInitialize(draft, action);
        break;
      case "clearAfterSync":
        handleClearAfterSync(draft, action);
        break;
      case "updateValidationsNeedingRequest":
        handleUpdateValidationsNeedingRequest(draft, action);
        break;
      case "updateInitialMapCapturesGenerated":
        handleUpdateInitialMapCapturesGenerated(draft, action);
        break;
      case "REPLACE_STATE_DO_NOT_CALL":
        handleREPLACE_STATE_DO_NOT_CALL(draft, action);
        break;
      default:
        exhaustiveGuard(action);
    }
  }

  const reducer = (draft: State, action: Action) => {
    handleAction(draft, action);
  };

  const [state, dispatch] = useImmerReducer(reducer, {
    formMap: getFormsIndex(formConfig),
    formInstanceMap: getFormInstancesIndex(
      formInstances.map((fi) => decode(fi.formJson) as IFormJson)
    ),
    configuration: formConfig,
    inspection,
    inspectionAssignment,
    formInstances: formInstances.map((fi) => decode(fi.formJson) as IFormJson),
    thoFormIndexesRequiringSync: [],
    onsiteCompleteSync: false,
    message: null,
    firmId: firmCtx.firm.id,
    personId: person.id,
    callback: null,
    expressionsNeedingRequest: [],
    mapCapturesNeedingGeneration: [],
    onsiteCompleteTriggered: false,
  } as State);

  useEffect(() => {
    if (inspectionAssignment) {
      dispatch({
        type: "REPLACE_STATE_DO_NOT_CALL",
        payload: {
          formMap: getFormsIndex(formConfig),
          formInstanceMap: getFormInstancesIndex(
            formInstances.map((fi) => decode(fi.formJson) as IFormJson)
          ),
          configuration: formConfig,
          inspection,
          inspectionAssignment,
          formInstances: formInstances.map((fi) => {
            const decoded = decode(fi.formJson) as IFormJson;

            return decoded;
          }),
          thoFormIndexesRequiringSync: [],
          onsiteCompleteSync: false,
          message: null,
          firmId: firmCtx.firm.id,
          personId: person.id,
          expressionsNeedingRequest: [],
          mapCapturesNeedingGeneration: [],
          //callback: null
        },
      });
    }
  }, [
    inspectionAssignment,
    dispatch,
    formConfig,
    inspection,
    formInstances,
    person.id,
    firmCtx,
  ]);

  let loading = useDebounce({
    stateToWatch: state.thoFormIndexesRequiringSync,
    debounceTimeoutMS: 1000,
    callback: () => {
      if (state.thoFormIndexesRequiringSync.length > 0) {
        let promises = state.thoFormIndexesRequiringSync.map((i) => {
          let formInstance = JSON.parse(
            JSON.stringify(formInstances[i])
          ) as IFormInstance;
          formInstance.formJson = encode(state.formInstances[i]);
          let { __typename, ...formInstanceToUpload } =
            formInstance as IFormInstance & { __typename: string };
          return thoFormInstanceUpdate({
            variables: { thoFormInstance: formInstanceToUpload },
          });
        });
        Promise.all(promises)
          .then(() => {
            dispatch({ type: "clearAfterSync", payload: { type: "formSave" } });
          })
          .catch((e) => {});
      }
    },
    shouldDebounceRun: (indexes) => (indexes && indexes.length > 0) ?? false,
  });

  // useEffect(() => {
  //   if (state.thoFormIndexesRequiringSync.length > 0) {
  //     let promises = state.thoFormIndexesRequiringSync.map((i) => {
  //       let formInstance = JSON.parse(
  //         JSON.stringify(formInstances[i])
  //       ) as IFormInstance;
  //       formInstance.formJson = encode(state.formInstances[i]);
  //       let { __typename, ...formInstanceToUpload } =
  //         formInstance as IFormInstance & { __typename: string };
  //       return thoFormInstanceUpdate({
  //         variables: { thoFormInstance: formInstanceToUpload },
  //       });
  //     });
  //     Promise.all(promises)
  //       .then(() =>
  //         dispatch({ type: "clearAfterSync", payload: { type: "formSave" } })
  //       )
  //       .catch((e) => {});
  //   }
  // }, [
  //   state.thoFormIndexesRequiringSync,
  //   dispatch,
  //   formInstances,
  //   state.formInstances,
  //   thoFormInstanceUpdate,
  // ]);

  useEffect(() => {
    if (state.message) {
      // state.message.
      enqueueSnackbar(state.message.text, { variant: state.message.style });
      dispatch({ type: "clearAfterSync", payload: { type: "message" } });
    }
  }, [dispatch, enqueueSnackbar, state.message]);

  useEffect(() => {
    if (!processedTemplate) {
      dispatch({ type: "initialize" });

      setProcessedTemplate(false);
    }
  }, [processedTemplate, dispatch]);

  useEffect(() => {
    if (state.onsiteCompleteSync) {
      const date = new Date();
      const dateString = date.toISOString();
      updateStatus({
        variables: {
          inspectionStatus: {
            statusDate: dateString,
            status: "Onsite Complete",
            isDeleted: false,
            createdPersonId: person.id,
            inspectionId: inspection.id,
          },
          inspectionAssignmentStatus: {
            statusDate: dateString,
            status: "Onsite Complete",
            createdPersonId: person.id,
            inspectionAssignmentId: inspectionAssignment.id,
            isDeleted: false,
          },
        },
      }).then(() =>
        dispatch({
          type: "clearAfterSync",
          payload: {
            type: "onsiteComplete",
          },
        })
      );
    }
  }, [
    state,
    updateStatus,
    inspection.id,
    inspectionAssignment.id,
    person.id,
    dispatch,
  ]);
  return (
    <>
      <WebInspectionCtx.Provider value={{ state, dispatch }}>
        {children}
      </WebInspectionCtx.Provider>
    </>
  );
};
export default Controller;

export function runValidationsForExpressions(
  formJson: IFormJson,
  expressionFieldsToCompare: ExpressionFieldToCompare[] | null,
  draft: State,
  geoRuleExpressions?: Expression[]
) {
  if (expressionFieldsToCompare) {
    for (let i = 0; i < expressionFieldsToCompare.length; i++) {
      let expF = expressionFieldsToCompare[i];
      runValidation(
        formJson,
        draft.formMap.formMap[formJson.formId].expressionsMap
          .expressionLocation[expF.expressionId].validationIndex,
        draft
      );
    }
  }
  if (geoRuleExpressions) {
    for (let i = 0; i < geoRuleExpressions.length; i++) {
      let expF = geoRuleExpressions[i];
      runValidation(
        formJson,
        draft.formMap.formMap[formJson.formId].expressionsMap
          .expressionLocation[expF.id].validationIndex,
        draft
      );
    }
  }
}

export function runValidation(
  formJson: IFormJson,
  validationIndex: number,
  draft: State
) {
  const form = draft.formMap.formMap[formJson.formId].form;
  let triggerValidation: boolean = false;
  let validation = form.validations[validationIndex];
  for (let i = 0; i < validation.expressions.length; i++) {
    let exp = validation.expressions[i];
    const expressionTriggered = checkExpression(
      exp,
      formJson,
      draft.formMap.formMap,
      draft.expressionsNeedingRequest
    );
    switch (exp.linkedBy) {
      case null:
        triggerValidation = expressionTriggered;
        break;
      case "AND":
        triggerValidation = triggerValidation && expressionTriggered;
        break;
      case "OR":
        triggerValidation = triggerValidation || expressionTriggered;
        break;
      default:
        exhaustiveGuard(exp.linkedBy);
    }
  }
  let formField =
    formJson.fields[
      draft.formMap.formMap[formJson.formId].fieldsIndexMap[validation.field]
    ];
  let configurationField: any = form.fields[formJson.fields.indexOf(formField)];
  let possibleValues: any = configurationField.possibleValues;

  if (formField.inputType === "MapCapture") {
  }
  var shouldRerunValueChangeValidations = false;
  switch (validation.action) {
    case "Show":
      formField.isVisible = triggerValidation;
      formField.formInput.errors = [];
      if (!triggerValidation) {
        formField.formInput.isValid = true;

        setValueNull(formField.formInput.formValue);
        shouldRerunValueChangeValidations = true;
      }
      break;
    case "Error": {
      if (!formField.isVisible) {
        formField.formInput.errors = [];
        formField.formInput.isValid = true;
        break;
      }
      formField.formInput.isValid = triggerValidation;
      if (triggerValidation) {
        if (!formField.formInput.errors.includes(validation.message)) {
          formField.formInput.errors.push(validation.message);
          formField.formInput.isValid = false;
        }
      } else {
        const indexValue = formField.formInput.errors.indexOf(
          validation.message
        );
        if (indexValue !== -1) {
          formField.formInput.errors.splice(indexValue, 1);
        }
        formField.formInput.isValid = formField.formInput.errors.length === 0;
      }
      break;
    }
    case "Answer": {
      if (!formField.isVisible) {
        break;
      }
      if (!formField.formInput.formValue) {
        formField.formInput.formValue = generateFormValue(
          formJson,
          formField,
          draft.firmId,
          draft.personId
        );
      }
      if (triggerValidation) {
        shouldRerunValueChangeValidations = true;
        switch (formField.inputType) {
          case "Checklist": {
            for (let opIndex = 0; opIndex < possibleValues.length; opIndex++) {
              let checkValues = [];
              if (validation.value.includes(possibleValues[opIndex].id)) {
                checkValues.push(possibleValues[opIndex].label);
              }
              formField.formInput.formValue.textValues = checkValues;
            }
            break;
          }
          case "YesNo": {
            let yesNoResult = false;
            if (validation.value === "true" || validation.value === true) {
              yesNoResult = true;
            }
            formField.formInput.formValue.booleanValue = yesNoResult;
            break;
          }
          case "OptionSelect": {
            for (let opIndex = 0; opIndex < possibleValues.length; opIndex++) {
              if (validation.value === possibleValues[opIndex].id) {
                formField.formInput.formValue.textValue =
                  possibleValues[opIndex].label;
              }
            }
            break;
          }
          case "DropDown": {
            for (let ddIndex = 0; ddIndex < possibleValues.length; ddIndex++) {
              if (validation.value === possibleValues[ddIndex].id) {
                formField.formInput.formValue.textValue =
                  possibleValues[ddIndex].label;
              }
            }
            break;
          }
          case "Text":
          case "LongText": {
            formField.formInput.formValue.textValue = validation.value;
            break;
          }
          case "Number": {
            let newFloat = parseFloat(validation.value);
            if (!isNaN(newFloat)) {
              formField.formInput.formValue.doubleValue = validation.value;
            } else {
              let newInt = parseInt(validation.value);
              if (!isNaN(newInt)) {
                formField.formInput.formValue.integerValue = validation.value;
              }
            }
            break;
          }
        }
      }
    }
  }
  if (shouldRerunValueChangeValidations) {
    let expressionsEffected =
      draft.formMap.formMap[formField.formId].expressionsMap
        .expressionFieldToCompare[formField.formFieldId] ?? [];
    if (expressionsEffected) {
      if (expressionsEffected.length > 0) {
        let orderedExpressionsEffected = [
          ...expressionsEffected.filter(
            (exp) =>
              exp.validationAction === "Show" &&
              exp.validationTrigger === "ValueChange"
          ),
          ...expressionsEffected.filter(
            (exp) =>
              exp.validationAction === "Error" &&
              exp.validationTrigger === "ValueChange"
          ),
          ...expressionsEffected.filter(
            (exp) =>
              exp.validationAction === "Answer" &&
              exp.validationTrigger === "ValueChange"
          ),
        ];
        runValidationsForExpressions(
          formJson,
          orderedExpressionsEffected,
          draft
        );
      }
    }
  }
}

export function generateFormValue(
  form: IFormJson,
  field: IFormField,
  firmId: string,
  personId: string
): IFormValue {
  return {
    beginDateValue: null,
    booleanValue: null,
    createdAt: null,
    createdBy: personId,
    doubleValue: null,
    endDateValue: null,
    entityId: "646ac12c-0f3b-11ed-9668-2eed6cdb025f",
    firmId: firmId,
    formConfigurationId: form.formConfigurationId,
    formFieldId: field.formFieldId,
    formFieldInstanceId: field.formInput.formFieldInstanceId,
    formId: form.formId,
    formInstanceId: field.formInput.formFieldInstanceId,
    id: uuidv4(),
    inspectionAssignmentId: form.inspectionAssignmentId,
    inspectionId: form.inspectionId,
    inspectionTemplateId: form.inspectionTypeTemplateId,
    integerValue: null,
    isDeleted: false,
    isDirty: false,
    jsonValue: null,
    name: "",
    parentId: null,
    photoIds: null,
    referenceType: "HomeInspectionAnswer",
    textValue: null,
    textValues: null,
    valueType: null,
  };
}

export function checkExpression(
  exp: Expression,
  formJson: IFormJson,
  formMap: FormMap,
  expressionsNeedingRequest: ExpressionNeedingRequest[] | null
): boolean {
  if (exp.type === "Field") {
    // check operator being compared against reference value
    const operator = exp.operator;

    // get IFormField value
    if (exp.fieldToCompare === null) return false;
    const currentField =
      formJson.fields[
        formMap[formJson.formId].fieldsIndexMap[exp.fieldToCompare]
      ];

    const form = formMap[formJson.formId];

    const formField =
      form.form.fields[form.fieldsIndexMap[currentField.formFieldId]];

    // get expression reference value
    const referenceValue =
      formField.inputType === "DropDown" ||
      formField.inputType === "OptionSelect"
        ? formField.possibleValues.find((pv) => pv.id === exp.value)?.label
        : formField.inputType === "Checklist"
        ? formField.possibleValues
            .filter((e) => exp.value.includes(e.id))
            .map((f) => f.label)
        : exp.value;

    const fieldValue = currentField.isVisible
      ? getCurrentFieldValue(currentField)
      : null;

    function contains(fieldVal: any, refVal: any, oneOf?: boolean) {
      if (!fieldVal) return false;
      if (fieldVal instanceof Array) {
        let result = arrayContainsArray(fieldVal, refVal, oneOf);
        return result;
      } else {
        let result = fieldVal.contains(refVal);
        return result;
      }
    }
    function arrayContainsArray(fieldVal: any, refValue: any, oneOf?: boolean) {
      if (!fieldVal) return false;
      return oneOf
        ? refValue.some((ref: any) => fieldVal.includes(ref))
        : refValue.every((r: any) => fieldVal.includes(r));
    }

    //

    switch (operator) {
      case null:
        return false;
      case "!==":
        return fieldValue !== referenceValue;
      case "<":
        return fieldValue < referenceValue;
      case "==":
        return fieldValue === referenceValue;
      case ">":
        return fieldValue > referenceValue;
      case "contains":
        return contains(fieldValue, referenceValue);
      case "does not contain":
        return !contains(fieldValue, referenceValue);
      case "contains one of":
        return contains(fieldValue, referenceValue, true);
      case "does not contain one of":
        return !contains(fieldValue, referenceValue, true);
      case "not null":
        return (
          fieldValue !== null && fieldValue !== undefined && fieldValue !== ""
        );
      default:
        exhaustiveGuard(operator);
    }
  }
  if (exp.type === "GeoRule") {
    var currExpressionNeedingRequest = expressionsNeedingRequest?.filter(
      (e) => e.expressionId === exp.id
    );
    if (currExpressionNeedingRequest) {
      if (currExpressionNeedingRequest.length > 0) {
        return currExpressionNeedingRequest[0].value === exp.value;
      }
    }
    return false;
  }
  return false;
}

export function getCurrentFieldValue(formField: IFormField) {
  const formValue = formField.formInput.formValue;

  if (!formValue) return null;
  return formValue[formValue.valueType as keyof IFormValue];
}

export async function initialize(
  formJson: IFormJson,
  draft: State,
  person: any,
  project: any
) {
  let form = draft.formMap.formMap[formJson.formId];
  if (form) {
    let formConfiguration = form.form;
    if (formConfiguration.geoFeatureConfigurations) {
      if (formConfiguration.geoFeatureConfigurations.length > 0) {
        if (draft.mapCapturesNeedingGeneration) {
          if (
            draft.mapCapturesNeedingGeneration.filter(
              (e) => e.formInstance.id === formJson.id
            ).length < 1
          ) {
            if (!formJson.initialMapGenerated) {
              draft.mapCapturesNeedingGeneration.push({
                formInstance: formJson,
              });
            }
          }
        }
        // generateMapCapture(
        //   { captureArgs: { formInstance: formJson } },
        //   draft,
        //   person,
        //   project,
        //   generateMapCaptureFromConfigFunction
        // );
      }
    }
    let geoExpression =
      draft.formMap.formMap[formJson.formId].expressionsMap.expressionByType[
        "GeoRule"
      ];

    if (geoExpression) {
      let expressionsList: ExpressionNeedingRequest[] = geoExpression.map(
        (ge) => {
          let expressionLocation =
            draft.formMap.formMap[formJson.formId].expressionsMap
              .expressionLocation[ge];
          let validation =
            draft.formMap.formMap[formJson.formId].form.validations[
              expressionLocation.validationIndex
            ];
          let expression =
            validation.expressions[expressionLocation.expressionIndex];
          let requestStructure: ExpressionNeedingRequest = {
            id: uuidv4(),
            expressionId: expression.id,
            formId: formJson.formId,
            validationId: validation.id,
            fieldId: validation.field,
            geoRuleId: expression?.geoRuleToCompare ?? "",
            inspectionId: formJson.inspectionId,
            value: null,
          };
          return requestStructure;
        }
      );
      draft.expressionsNeedingRequest = expressionsList;
    }

    formJson.fields.forEach((field) => {
      const fieldIndex =
        draft.formMap.formMap[field.formId].fieldsIndexMap[field.formFieldId];
      const formField =
        draft.formMap.formMap[field.formId].form.fields[fieldIndex];
      // Handle most fields updating
      field.referenceMaterialIds = formField.referenceMaterialIds ?? null;
      field.label = formField.label;
      field.description = formField.description;
      field.displayIndex = formField.displayIndex;
      if (field.formInput.formValue) {
        if (
          formField.inputType === "OptionSelect" ||
          formField.inputType === "DropDown"
        ) {
          // If field is set to Possible Value which no longer exists, remove possible value
          if (
            field.formInput.formValue.textValue &&
            !formField.possibleValues
              .map((pv) => pv.label)
              .includes(field.formInput.formValue.textValue)
          )
            field.formInput.formValue.textValue = null;
        }
      }
      formJson.subFormInstances.forEach((subform) => {
        initialize(subform, draft, person, project);
      });
    });
  }
}

export function validateForm(
  formJson: IFormJson,
  draft: State,
  trigger: ValidationTrigger | "all"
) {
  let c = current;
  let formInvalid = false;
  let formTemplate = draft.formMap.formMap[formJson.formId].form;
  let geoExpressionsEffected: Expression[] = [];

  let geoRuleExpressionsEffected =
    draft.formMap.formMap[formJson.formId].expressionsMap.expressionByType[
      "GeoRule"
    ];
  if (geoRuleExpressionsEffected) {
    if (geoRuleExpressionsEffected.length > 0) {
      let geoExps = geoRuleExpressionsEffected.map((ge) => {
        let expressionLocation =
          draft.formMap.formMap[formJson.formId].expressionsMap
            .expressionLocation[ge];
        let validation =
          draft.formMap.formMap[formJson.formId].form.validations[
            expressionLocation.validationIndex
          ];
        let expression =
          validation.expressions[expressionLocation.expressionIndex];
        if (validation.onTrigger === trigger) {
          return expression;
        }
      });
      for (let j = 0; j < geoExps.length; j++) {
        if (geoExps[j] !== undefined && geoExps[j] !== null) {
          geoExpressionsEffected.push(geoExps[j] as Expression);
        }
      }

      runValidationsForExpressions(
        formJson,
        null,
        draft,
        geoExpressionsEffected
      );
    }
  }

  for (let j = 0; j < formJson.fields.length; j++) {
    let field = formJson.fields[j];
    let expressionsEffected =
      draft.formMap.formMap[field.formId].expressionsMap
        .expressionFieldToCompare[field.formFieldId] ?? [];

    let orderedExpressionsEffected = [
      ...expressionsEffected.filter(
        (exp) =>
          exp.validationAction === "Show" &&
          (exp.validationTrigger === trigger || trigger === "all")
      ),
      ...expressionsEffected.filter(
        (exp) =>
          exp.validationAction === "Error" &&
          (exp.validationTrigger === trigger || trigger === "all")
      ),
      ...expressionsEffected.filter(
        (exp) =>
          exp.validationAction === "Answer" &&
          (exp.validationTrigger === trigger || trigger === "all")
      ),
    ];

    runValidationsForExpressions(formJson, orderedExpressionsEffected, draft);

    if (trigger !== "FormCreate") {
      const required = formTemplate.fields[j].isRequired;

      // No value OR nothing in the value slot (besides 0) OR the value is an empty array
      const noValue =
        !field.formInput.formValue ||
        (!field.formInput.formValue[
          field.formInput.formValue.valueType as keyof IFormValue
        ] &&
          field.formInput.formValue[
            field.formInput.formValue.valueType as keyof IFormValue
          ] !== 0 &&
          field.formInput.formValue[
            field.formInput.formValue.valueType as keyof IFormValue
          ] !== false) ||
        (field.formInput.formValue.valueType === "photoIds" &&
          (!field.formInput.formValue.photoIds ||
            field.formInput.formValue["photoIds"].length === 0));

      const invalidValue = noValue && required;
      if (invalidValue) {
        if (!field.formInput.errors.includes("This field is required."))
          field.formInput.errors.push("This field is required.");
      }
      field.formInput.isValid = field.formInput.errors.length === 0;
      if (!field.isVisible) field.formInput.isValid = true;
      if (!field.formInput.isValid) {
        formInvalid = true;
      }
    }
  }

  if (trigger === "FormCreate") {
    formJson.needsInitialValidations = false;
  }
  let invalidChild = false;

  const validity = !invalidChild && !formInvalid;
  formJson.isValid = validity;
  return validity;
}

export function setValueNull(formValue: IFormValue | undefined) {
  if (formValue) {
    formValue.beginDateValue = null;
    formValue.booleanValue = null;
    formValue.doubleValue = null;
    formValue.integerValue = null;
    formValue.endDateValue = null;
    formValue.jsonValue = null;
    formValue.textValue = null;
    formValue.textValues = null;
  }
}

export function getTopLevelFormInstance(formJson: IFormJson, draft: State) {
  let result = formJson;

  if (formJson.parentFormInstanceId) {
    let parentFormJson: IFormJson =
      draft.formInstanceMap[formJson.parentFormInstanceId];
    result = getTopLevelFormInstance(parentFormJson, draft);
  }

  return result;
}
