import SchemaContext from "./SchemaContext";
import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import { TextField, DateTimePicker, Switch, Int, Enum } from "../mui-rhf";
import { Button } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {
  getMutationInputs,
  getMutationReturn,
  generateMutation,
} from "./MutationHelpers";
import Alert from "@mui/material/Alert";

function processOptions(values) {
  return values.map((value) => {
    return { label: value.name, value: value.name };
  });
}
function switchScalarTypes(name, formComponentProps, schema, input) {
  switch (name) {
    case "String":
      return TextField(formComponentProps);
    case "DateTime":
      return DateTimePicker(formComponentProps);
    case "Boolean":
      return Switch(formComponentProps);
    case "Int":
      return Int(formComponentProps);
    default:
      console.log(
        "Non-specific name " + name + " rendering standard text field"
      );
      return TextField(formComponentProps);
  }
}
function getFormComponent(input, skipFields, schema) {
  // TODO correct error checking
  if (skipFields && skipFields[input.name]) return null;
  // if (input.type !== 'ENUM' && input.type.kind !== 'NON_NULL' && input.type.kind !== 'SCALAR') {
  //   console.log("ERROR UNADDRESSED NON-SCALAR TYPE IN GER FORM COMPONENT: ", input.type.ofType.kind)
  // }
  // if (input.type !== 'ENUM' && input.type.kind === 'NON_NULL' && input.type.ofType.kind !== 'SCALAR') {
  //   console.log("ERROR UNADDRESSED NON-SCALAR TYPE IN GER FORM COMPONENT: ", input.type.ofType.kind)
  // }
  let required = input.type.kind === "NON_NULL";
  let type;
  if (input.type.kind === "NON_NULL") type = input.type.ofType.kind;
  else type = input.type.kind;

  let formComponentProps = {
    key: input.name,
    name: input.name,
    rules: { required: required },
    label:
      input.description ||
      input.name
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim(),
  };
  
  switch (type) {
    case "SCALAR":
      return switchScalarTypes(
        input.type.name,
        formComponentProps,
        schema,
        input
      );
    case "ENUM":
      if (required) {
        let options = processOptions(
          schema.enums[input.type.ofType.name].enumValues
        );
        formComponentProps = { ...formComponentProps, options };
        return Enum(formComponentProps, schema);
      } else {
        let options = processOptions(schema.enums[input.type.name].enumValues);
        formComponentProps = { ...formComponentProps, options };
        return Enum(formComponentProps, schema);
      }
    case "LIST":
      return null;
    default:
      console.log(
        "Non-specific name " + type + "rendering standard text field"
      );
      return TextField(formComponentProps);
  }
}

const orderInputs = (inputsOrFields, inputOrder) => {
  let inputs;
  let fields;

  if (Array.isArray(inputsOrFields[0])) {
    fields = inputsOrFields;
  } else {
    inputs = inputsOrFields;
  }

  const orderedInputs = (inputs || fields.map((fields) => ({ fields })))
    .filter((input) => input !== undefined)
    .map((input) => {
      const orderedFields = (input.fields || [])
        .filter((field) => !!field) // remove any falsy fields
        .sort((a, b) => {
          const aOrder = inputOrder?.[a.name]?.order ?? Infinity;
          const bOrder = inputOrder?.[b.name]?.order ?? Infinity;
          return aOrder - bOrder;
        });

      return {
        ...input,
        fields: orderedFields,
      };
    });

  let flatFields = [];
  orderedInputs.forEach((input) => {
    if (Array.isArray(input.fields) && input.fields.length > 0) {
      input.fields.forEach((field) => {
        flatFields.push(field);
      });
    } else {
      flatFields.push(input);
    }
  });

  return flatFields.filter((field) => !!field);
};

const FormComponents = ({ inputs, inputOrder, skipFields, schema }) => {
  const orderedInputs = orderInputs(inputs, inputOrder);
  const formComponents = orderedInputs.map((input, index) => {
    if (!input) return null;
    if (skipFields && skipFields[input.name]) return null;
    let newInputOrder =
      inputOrder && inputOrder[input.name]
        ? inputOrder[input.name].inputOrder
        : {};
    if (input.fields) {
      console.log(skipFields);
      return (
        <div key={`div${input.name}`}>
          <h1 key={`h1${input.name}`}>{input.description}</h1>
          <Grid container>
            <FormComponents
              key={`fc${input.name}`}
              inputs={input.fields}
              skipFields={skipFields}
              inputOrder={newInputOrder}
              schema={schema}
            />
          </Grid>
        </div>
      );
    } else {
      return (
        <Grid
          item
          xs={12}
          style={{ margin: 4 }}
          rest={{ ...input.inputOverrides }}
        >
          {" "}
          {getFormComponent(input, skipFields, schema)}{" "}
        </Grid>
      );
    }
  });
  return formComponents;
};
const DeleteButton = (deleteMutationString, deleteId, schema) => {
  const deleteMutation = schema.mutations[deleteMutationString];
  const deleteMutationInputs = getMutationInputs(deleteMutation, schema);
  const deleteMutationReturn = getMutationReturn(deleteMutation, schema);
  const deleteMutationDocument = generateMutation(
    deleteMutationInputs,
    deleteMutationReturn,
    deleteMutationString
  );
  const [deleteMutate] = useMutation(deleteMutationDocument);
  const onDelete = () => {
    deleteMutate({ variables: { id: deleteId } })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };
  return (
    <Button variant="contained" onClick={onDelete}>
      delete
    </Button>
  );
};
const FormActions = (formActions) =>
  formActions.map((action) => (
    <Button variant="contained" onClick={action.callback}>
      {action.text}
    </Button>
  ));

const RenderChildren = (children, methods) => {
  if (!children) {
    return null;
  }
  if (Array.isArray(children)) {
    return children.map((child) => {
      return React.cloneElement(child, { methods: methods });
    });
  } else {
    return React.cloneElement(children, { methods: methods });
  }
};

export const GQLForm = ({
  update,
  mutationString,
  defaultValues,
  label,
  onCompleted,
  deleteMutationString,
  deleteId,
  formActions,
  skipFields,
  inputOrder,
  additionalVariables,
  children,
}) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;
  const schema = useContext(SchemaContext);
  const [showUpdate, setShowUpdate] = useState(false);
  const mutation = schema.mutations[mutationString];
  const mutationInputs = getMutationInputs(mutation, schema);
  const mutationReturn = getMutationReturn(mutation, schema);
  const mutationDocument = generateMutation(
    mutationInputs,
    mutationReturn,
    mutationString
  );
  const [mutate, { error }] = useMutation(mutationDocument, { onCompleted });
  if (error) {
    console.log("Error retrieving data: ", error);
  }
  const onSubmit = (data) => {
    const variables = { ...data, ...additionalVariables };
    mutate({ variables })
      .then((res) => onCompleted())
      .catch((e) => {
        console.log(e);
      });
  };

  watch((data) => {
    setShowUpdate(true);
  });
  return (
    <div style={{ width: "100%" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormComponents
            key={"topfc"}
            inputs={mutationInputs}
            skipFields={skipFields}
            inputOrder={inputOrder}
            schema={schema}
          />
          {error && (
            <Alert severity="error" variant="filled">
              {error.message}
            </Alert>
          )}
          {RenderChildren(children, methods)}
          {(!update || showUpdate) && (
            <Button variant="contained" type="submit">
              {update ? "Update" : "submit"}
            </Button>
          )}
          {formActions && FormActions(formActions)}
          {deleteMutationString &&
            deleteId &&
            DeleteButton(deleteMutationString, deleteId, schema)}
        </form>
      </FormProvider>
    </div>
  );
};
