import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  ExitToApp,
  ArrowBack,
  ArrowForward,
  CheckCircle,
  Cancel,
  Close,
  Error,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import { PersonContext } from "src/utils/contexts/person";
import { ProjectContext } from "src/utils/contexts/project";
import { FirmContext } from "src/utils/contexts/firm";
import { encode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/encode";
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
import { Icon } from "@iconify/react";
import { DataReplication } from "./replicate";
import checkboxColumn from "../Create/CheckboxColumn";
import {
  IFormInstance,
  IFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import { decode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/decode";
import {
  downloadZip,
  genPDFContent,
  genPDFs,
} from "../MyInspections/routes/InspectionWebView/downloadReport";
import JSZip from "jszip";
import { encodeToBase64 } from "pdf-lib";

const REPORT_TEMPLATES_QUERY = gql`
  query ReportTypeTemplates(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplates(reportTypeTemplate: $reportTypeTemplate) {
      id
      reportType {
        type
      }
      templateUrl
      configuration
    }
  }
`;

const DATA_REPLICATION_REQUEST = gql`
  query DataReplicationRequests(
    $dataReplicationRequest: InputDataReplicationRequestParams!
  ) {
    dataReplicationRequests(dataReplicationRequest: $dataReplicationRequest) {
      id
      personId
      sourceInspectionAssignment
      destinationProjectLocations
      destinationProjectLocationsFormatted
      sourceLocationFormatted
      jobId
      jobStatus
      insertedAt
      resultInspectionAssignments
    }
  }
`;

const GET_MAP_CAPTURES = gql`
  query MapCaptures($mapCapture: InputMapCaptureParams!) {
    mapCaptures(mapCapture: $mapCapture) {
      id
      description
      title
      metadata
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

const THO_FORM_INSTANCES = gql`
  query ThoFormInstances($thoFormInstance: InputThoFormInstanceParams) {
    thoFormInstances(thoFormInstance: $thoFormInstance) {
      id
      inspectionId
      inspectionAssignmentId
      formId
      formConfigurationId
      inspectionTypeTemplateId
      inspectionTypeTemplate {
        configuration
        description
        end
        id
        isDeleted
        name
        start
      }
      reference
      referenceType
      formJson
      name
      type
      isDeleted
    }
  }
`;

const INSPECTION_ASSIGNMENTS = gql`
  query InspectionAssignments(
    $inspectionAssignment: InputInspectionAssignmentParams!
  ) {
    inspectionAssignments(inspectionAssignment: $inspectionAssignment) {
      id
      personId
      firmPersonId
      inspection {
        id
        number
        inspectionLocation
        location {
          id
          description
          city
          buildingType
          identifier
          lat
          lis
          lng
          mapboxId
          name
          neighborhood
          owner
          parentId
          projectId
          state
          street1
          street2
          street3
          tenant
          unit
          zip
        }
        inspectionType {
          id
          name
        }
      }
      thoFormInstances {
        id
        inspectionId
        inspectionAssignmentId
        formId
        formConfigurationId
        inspectionTypeTemplateId
        inspectionTypeTemplate {
          configuration
          description
          end
          id
          isDeleted
          name
          start
        }
        reference
        referenceType
        formJson
        name
        type
        isDeleted
      }
      inspectionId
      start
      end
      formChangesAcknowledged
      frontElevationPhotoCaptured
      isCheckedIn
      notesAcknowledged
      eSignatureAcknowledged
      rightOfEntryCaptured
      onsiteComplete
      inaccessible
      stopWorkHazardPresent
      status
      requiresDrawing
      isDeleted
      isMobile
      isWeb
    }
  }
`;

const UPLOAD_REPORT_RESULT = gql`
  mutation UploadReportResult(
    $reportResult: InputReportResultParams!
  ) {
    uploadReportResult(reportResult: $reportResult) {
      id
    }
  }
`;

export const DataReplicationRequestResults = () => {
  const params = useParams();
  const person: any = useContext(PersonContext);
  const project: any = useContext(ProjectContext);
  const [dataReplicationRequest, setDataReplicationRequest] = useState<any>(null);
  const [selectionModel, setValidatedSelectionModel] = useState([]);
  const [selectionRecord, setValidatedSelectionRecord] = useState([]);
  const [reportTypeTemplates, setReportTypeTemplates] = useState([]);
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [generatingPDFs, setGeneratingPDFs] = useState(false);
  const [loadedFormData, setLoadedFormData] = useState([]);
  const [reportResultsToUpload, setReportResultsToUpload] = useState([]);
  const [loadedInspectionAssignments, setLoadedInspectionAssignments] =
    useState([]);


    const [uploadReportResult] = useMutation(UPLOAD_REPORT_RESULT, {
      fetchPolicy: "network-only",
      onCompleted: (response) => {},
    });
  const [getReportTypeTemplates, { called, data }] = useLazyQuery(
    REPORT_TEMPLATES_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    inspectionAssignmentQuery,
    { data: inspectionAssignmentData, loading: loadingAssignments },
  ] = useLazyQuery(INSPECTION_ASSIGNMENTS, {
    fetchPolicy: "network-only",
  });

  const [getMapCaptures] = useLazyQuery(GET_MAP_CAPTURES, {
    fetchPolicy: "network-only",
  });

  const [
    dataReplicationRequestQuery,
    { data: replicationRequestData, loading: replicationRequestLoading },
  ] = useLazyQuery(DATA_REPLICATION_REQUEST, {
    fetchPolicy: "network-only",
    onCompleted: (results) => {

        setDataReplicationRequest(results.dataReplicationRequests[0]);
    },
    onError: (error) => {
      debugger;
    }
  });

  useEffect(() => {
    if (!replicationRequestData && !replicationRequestLoading && params) {
      dataReplicationRequestQuery({
        variables: {
          dataReplicationRequest: {
            id: params.replicationRequestId,
          },
        },
      });
    }
  }, [params]);

  useEffect(() => {
    if (dataReplicationRequest && !replicationRequestLoading) {
      var ranCount = 0;
      var ias: any = [];
      var allAssignmentIds = dataReplicationRequest.resultInspectionAssignments;
      allAssignmentIds = [...allAssignmentIds, dataReplicationRequest.sourceInspectionAssignment];
      allAssignmentIds.forEach((e: any) => {
        inspectionAssignmentQuery({
          variables: {
            inspectionAssignment: {
              id: e,
            },
          },
        }).then((result: any) => {
          var ia = result.data.inspectionAssignments[0];
          var myLoc = ia.inspection.location;
          var iaWithAddress = {
            ...ia,
            formattedAddress:
              myLoc.street1 +
              " " +
              myLoc.city +
              " " +
              myLoc.state +
              " " +
              myLoc.zip,
          };
          ias.push(iaWithAddress);
          ranCount++;
          if (
            ranCount ===
            allAssignmentIds.length
          ) {
            setLoadedInspectionAssignments(ias);
            setLoadingFormData(true);
          }
        });
      });
    }
  }, [dataReplicationRequest]);

  useEffect(() => {
    if (loadingFormData) {
      let loadedInstancesData: any = [];
      let reportTemplates: any = [];
      let loadedCount = 0;
      let formInstancesLoadedCount = 0;
      let validChecks:any = [];
      let index = 0;
      loadedInspectionAssignments.forEach((ia: any) => {
        loadedCount++;
        let valid = true;
        let instances: IFormJson[] = ia.thoFormInstances.map(
          (instance: IFormInstance) => {
            var json = decode(instance.formJson);
            if(!json.isValid){
                valid = false;
            }
            return json;
          }
        );
        validChecks.push({isValid: valid, index: index});
        index++;
        instances.forEach((i)=>{
          loadedInstancesData.push(
          {
            inspectionId: ia.inspectionId,
            inspectionAssignmentId: ia.id,
            formInstance: i,
            inspectionTypeTemplateId: i.inspectionTypeTemplateId,
            address: ia.formattedAddress
          });
        })
        let formInstancesLoadedCount = 0;
        ia.thoFormInstances.forEach((fi: any) => {
          formInstancesLoadedCount++;
          getReportTypeTemplates({
            variables: {
              reportTypeTemplate: {
                inspectionTypeTemplateId: fi.inspectionTypeTemplate.id,
              },
            },
          }).then((r) => {
            r.data.reportTypeTemplates.forEach((rtt: any) => {
              let existing = reportTemplates.find((e: any) => e.id === rtt.id);
              if (!existing) {
                reportTemplates.push({
                  ...rtt,
                  inspectionTypeTemplateId: fi.inspectionTypeTemplate.id,
                });
              }
            });
            // console.log(formInstancesLoadedCount + ":" + ia.thoFormInstances.length);
            if (formInstancesLoadedCount === ia.thoFormInstances.length) {
              if (loadedCount === loadedInspectionAssignments.length) {
                let ias:any = [...loadedInspectionAssignments];
                validChecks.forEach((vc:any)=>{
                    let assignment:any = ias[vc.index];
                    assignment.isValid = vc.isValid;
                    ias[vc.index] = assignment;
                });
                setLoadedInspectionAssignments(ias);
                setLoadedFormData(loadedInstancesData);
                setLoadingFormData(false);
                setReportTypeTemplates(reportTemplates);
                setDataLoaded(true);
              }
            }
          });
        });
      });
    }
  }, [loadingFormData]);

  useEffect(()=>{}, [dataLoaded, generatingPDFs])

  useEffect(()=>{
    if(reportResultsToUpload){
      if(reportResultsToUpload.length > 0){
        let uploadedCount = 0;
        reportResultsToUpload.forEach((fp:any)=>{
          uploadReportResult(fp).then((result:any)=>{
            uploadedCount++;
            if(uploadedCount === reportResultsToUpload.length){
              setReportResultsToUpload([]);
              setGeneratingPDFs(false);
            }
          });
        });
      }
    }
  }, [reportResultsToUpload])

  const handleValidatedSelection = (ids: any) => {
    let selectedIDs = new Set(ids);
    let selectedRowData =
      loadedInspectionAssignments.filter((row: any) =>
        selectedIDs.has(row.id)
      );

    setValidatedSelectionRecord(selectedRowData);
    let idsArray: any = Array.from(selectedIDs);
    setValidatedSelectionModel(idsArray);
  };

  return (
    <>
      <Typography variant="h3">Data Replication</Typography>
      {!dataLoaded && (
        <CircularProgress />
      )}
      <Stack>
        {dataLoaded && (
          <Paper elevation={6}>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h4">Data Replication Results</Typography>
              <Paper elevation={6}>
                <Box sx={{ padding: 2 }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h4">Review</Typography>
                    {!generatingPDFs && (
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          setGeneratingPDFs(true);
                          let pdfs = reportTypeTemplates;
                          let formStates = loadedFormData;
                          let mapCaptures: any = null;
                          let currentZip = new JSZip();
                          let formStateCount = 0;
                          let formPdfs:any = [];
                          formStates.forEach((fs: any) => {
                            getMapCaptures({
                              variables: {
                                mapCapture: {
                                  inspectionId: fs.inspectionId,
                                  inspectionAssignmentId:
                                    fs.inspectionAssignmentId,
                                },
                              },
                            }).then((response: any) => {
                              if (response.data.mapCaptures) {
                                var responseMapCaptures: any = [
                                  ...response.data.mapCaptures,
                                ];
                                for (
                                  var i = 0;
                                  i < responseMapCaptures.length;
                                  i++
                                ) {
                                  var newMapCapture = {
                                    ...responseMapCaptures[i],
                                  };
                                  newMapCapture.image =
                                    "data:image/png;base64, " +
                                    newMapCapture.image;
                                  responseMapCaptures[i] = newMapCapture;
                                }
                                mapCaptures = responseMapCaptures;
                              }

                              
                              let myPDFs = pdfs.filter(
                                (pdf: any) =>{
                                  var templateId = Object.keys(pdf.configuration)[0]
                                  let name = pdf.configuration[templateId].name;
                                  return pdf.inspectionTypeTemplateId === fs.inspectionTypeTemplateId && 
                                  name === 
                                  fs.formInstance.name;
                                }
                              );

                              let focusedPDF = null;
                              if(myPDFs.length === 1){
                                focusedPDF = myPDFs[0];
                              }
                                genPDFContent(
                                  focusedPDF,
                                  fs,
                                  mapCaptures
                                ).then((result: any) => {
                                  formStateCount++;
                                  if(result){
                                    formPdfs.push(result);
                                  }
                                  if (formStateCount === formStates.length) {
                                    debugger;
                                    let reports: any = [...reportResultsToUpload]
                                    formPdfs.forEach((fp:any)=>{
                                      reports.push(
                                        {variables:{
                                          reportResult:{
                                            reportTypeTemplateId: fp.reportTemplateId,
                                            inspectionId: fp.formState.inspectionId,
                                            inspectionAssignmentId: fp.formState.inspectionAssignmentId,
                                            thoFormInstanceId: fp.formState.formInstance.id,
                                            documentBytes: encodeToBase64(fp.pdfBytes),
                                            requestedBy: person.person.id,
                                            fileName: fp.filename,
                                            fileExtension: ".pdf"
                                          }
                                        }}
                                      );
                                    });

                                    setReportResultsToUpload(reports);
                                  }
                                });
                            });
                          });
                        }}
                      >
                        Generate and Review Reports
                      </Button>)}
                      {generatingPDFs && (<CircularProgress></CircularProgress>)}
                  </Box>
                  <DataGridPremium
                    rows={loadedInspectionAssignments}
                    columnVisibilityModel={{
                      id: false,
                    }}
                    disableRowSelectionOnClick
                    columns={[
                      // { ...checkboxColumn(apiRef) },
                      { field: "id", headerName: "Id" },
                      {
                        field: "formattedAddress",
                        headerName: "Address",
                        width: 450,
                      },
                      {
                        field: "validationStatus",
                        headerName: "Validation Status",
                        width: 200,

                        renderCell: (params: any) => {
                          return (
                            // <Link to={`${params.number}?inspection=${params.id}`}>
                            //   <Edit />
                            // </Link>
                            params.row.isValid ? (
                              <CheckCircle color="success"></CheckCircle>
                            ) : (
                              <Cancel color="error"></Cancel>
                            )
                          );
                        },
                      },

                      {
                        field: "view",
                        headerName: "",
                        width: 10,

                        renderCell: (params: any) => {
                          return (
                            <Link
                              to={`/app/myInspections/${params.row.id}`}
                              target="_blank"
                            >
                              <ExitToApp />
                            </Link>
                          );
                        },
                      },
                    ]}
                  />
                </Box>
              </Paper>
            </Box>
          </Paper>
        )}
      </Stack>
    </>
  );
};
