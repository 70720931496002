import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Controller from "../controller";
import {
  AppointmentLevelFormTypes,
  Configuration,
  Field,
  Form,
  SpecialFormTypes,
  ValidationAction,
  ValidationTrigger,
} from "src/pages/Inspections/FormBuilder/components/TypeDefinitions";
import {
  IFormField,
  IFormInstance,
  IFormJson,
  IFormValue,
  ITopLevelFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import { v4 as uuidv4 } from "uuid";
import { PersonContext } from "src/utils/contexts/person";
import { encode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/encode";
import { FirmContext } from "src/utils/contexts/firm";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import { decode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/decode";
import processFormJsonReturnStatus from "./functions/processFormJsonReturnStatus";
import { J } from "@fullcalendar/core/internal-common";

const INSPECTION_ASSIGNMENT_QUERY = gql`
  query InspectionAssignment($inspectionAssignmentId: ID!) {
    inspectionAssignment(id: $inspectionAssignmentId) {
      id
      personId
      firmPersonId
      formChangesAcknowledged
      isCheckedIn
      notesAcknowledged
      eSignatureAcknowledged
      rightOfEntryCaptured
      onsiteComplete
      inaccessible
      stopWorkHazardPresent
      status
      requiresDrawing
      isDeleted
      isMobile
      isWeb
      inspection {
        id
        number
        inspectionLocation
        location {
          id
          description
          city
          buildingType
          identifier
          lat
          lis
          lng
          mapboxId
          name
          neighborhood
          owner
          parentId
          projectId
          state
          street1
          street2
          street3
          tenant
          unit
          zip
        }
        inspectionType {
          id
        }
      }
    }
  }
`;

const INSPECTION_QUERY = gql`
  query Inspection($inspection: InputInspectionParams!) {
    inspection(inspection: $inspection) {
      number
      id
      inspectionLocation
      inspectionType {
        id
        inspectionTypeTemplates {
          id
          start
        }
      }
    }
  }
`;

const INSPECTION_TYPE_QUERY = gql`
  query InspectionType($inspectionType: InputInspectionTypeParams) {
    inspectionType(inspectionType: $inspectionType) {
      id
      name
      inspectionTypeTemplates {
        id
        name
        description
        start
        end
        configuration
        isDeleted
      }
      isDeleted
    }
  }
`;

const THO_FORM_INSTANCES = gql`
  query ThoFormInstances($thoFormInstance: InputThoFormInstanceParams) {
    thoFormInstances(thoFormInstance: $thoFormInstance) {
      id
      inspectionId
      inspectionAssignmentId
      formId
      formConfigurationId
      inspectionTypeTemplateId
      reference
      referenceType
      formJson
      name
      type
      isDeleted
    }
  }
`;

const UPLOAD_FORM_INSTANCE = gql`
  mutation UploadThoFormInstance($thoFormInstance: InputThoFormInstanceParams) {
    uploadThoFormInstance(thoFormInstance: $thoFormInstance) {
      id
      name
    }
  }
`;

export const WebViewWrapper = ({
  children,
  inspectionAssignmentId
}: {
  children: ReactElement | ReactElement[];
  inspectionAssignmentId?: string | null;
}) => {
  const { assignmentId } = useParams();
  const [validatedAgainstTemplate, setValidatedAgainstTemplate] =
    useState(false);
  const [initializing, setInitializing] = useState(false);
  const personCtx: any = useContext(PersonContext);
  const firmCtx: any = useContext(FirmContext);

  const { data: inspectionAssignmentData, refetch } = useQuery(
    INSPECTION_ASSIGNMENT_QUERY,
    {
      variables: {
        inspectionAssignmentId: inspectionAssignmentId ? inspectionAssignmentId : assignmentId,
      },
      fetchPolicy: "network-only",
      onCompleted: () => {},
    }
  );

  const [
    inspectionQuery,
    { data: inspectionData, loading: inspectionLoading },
  ] = useLazyQuery(INSPECTION_QUERY);

  const [
    inspectionTypeQuery,
    { data: inspectionTypeData, loading: loadingInspectionType },
  ] = useLazyQuery(INSPECTION_TYPE_QUERY);

  const [
    formInstancesQuery,
    { data: formInstancesData, loading: loadingInstances },
  ] = useLazyQuery(THO_FORM_INSTANCES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
  });

  const [uploadFormInstance] = useMutation(UPLOAD_FORM_INSTANCE);

  // useEffect(() => {
  //   if (loadingInstances) {
  //     setValidatedAgainstTemplate(false);
  //   }
  // }, [loadingInstances]);

  useEffect(() => {
    if (
      inspectionAssignmentData &&
      !validatedAgainstTemplate &&
      !inspectionLoading
    ) {
      inspectionQuery({
        variables: {
          inspection: {
            id: inspectionAssignmentData.inspectionAssignment.inspection.id,
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [inspectionAssignmentData]);

  useEffect(() => {
    if (inspectionData && !validatedAgainstTemplate && !loadingInspectionType) {
      inspectionTypeQuery({
        variables: {
          inspectionType: {
            id: inspectionData.inspection.inspectionType.id,
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [inspectionData]);

  useEffect(() => {
    if (inspectionTypeData && !validatedAgainstTemplate && !loadingInstances) {
      formInstancesQuery({
        variables: {
          thoFormInstance: { inspectionAssignmentId: inspectionAssignmentId ? inspectionAssignmentId : assignmentId },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [inspectionTypeData]);

  // TODO: INITIALIZE FORMS IF NOT YET PRESENT
  useEffect(() => {
    if (
      formInstancesData &&
      !loadingInstances &&
      validatedAgainstTemplate === false &&
      !initializing
    ) {
      setInitializing(true);

      const uninitializedFormTypes = getUninitializedTopLevelForms(
        inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
          .configuration.forms,
        formInstancesData.thoFormInstances
      );

      if (uninitializedFormTypes.length !== 0) {
        const newInstances = uninitializedFormTypes.map((formId) => {
          return generateInstance(
            inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
              .configuration,
            formId,
            inspectionTypeData.inspectionType.inspectionTypeTemplates[0].id,
            inspectionData.inspection.id,
            (inspectionAssignmentId ? inspectionAssignmentId : assignmentId) ?? "",
            personCtx.person.firstName + " " + personCtx.person.lastName,
            inspectionData.inspection.number,
            firmCtx.firm.id,
            personCtx.person.id
          );
        });
        const promises = newInstances.map((instance) => {
          const {
            formId,
            formConfigurationId,
            type,
            reference,
            referenceType,
            name,
            id,
            isDeleted,
            inspectionTypeTemplateId,
            inspectionId,
            inspectionAssignmentId,
          } = instance;

          return uploadFormInstance({
            variables: {
              thoFormInstance: {
                type,
                requiresArchive: false,
                referenceType,
                reference,
                id,
                name,
                isDeleted,
                inspectionTypeTemplateId,
                inspectionId,
                inspectionAssignmentId,
                formId,
                formConfigurationId,
                formJson: encode(instance),
              },
            },
          });
        });
        Promise.all(promises).then(() => {
          formInstancesQuery({
            variables: {
              thoFormInstance: { inspectionAssignmentId: inspectionAssignmentId ? inspectionAssignmentId : assignmentId },
            },
            fetchPolicy: "network-only",
          }).then(() => {
            setValidatedAgainstTemplate(true);
            setInitializing(false);
          });
        });
      } else {
        if (!validatedAgainstTemplate) {
          const instances: IFormJson[] = formInstancesData.thoFormInstances.map(
            (instance: IFormInstance) => {
              var decoded = decode(instance.formJson);
              return decoded;
            }
          );
          let promises: Promise<any>[] = [];

          instances.forEach((instance) => {
            const initialInstanceValue = JSON.stringify(instance);
            let instanceCopy: IFormJson = JSON.parse(initialInstanceValue);

            let needsDelete = false;
            const formMap = getFormsIndex(
              inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
                .configuration
            );

            const form = formMap.formMap[instance.formId];
            if (!form) {
              needsDelete = true;
            } else {
              const myForm = form.form;
              const date = new Date();
              const now = date.toISOString();
              let status = processFormJsonReturnStatus(
                instanceCopy,
                formMap,
                {
                  firmId: firmCtx.firm.id,
                  personId: personCtx.person.id,
                  form: myForm,
                  now,
                  formJsonId: instance.id,
                  inspectionAssignmentId: (inspectionAssignmentId ? inspectionAssignmentId : assignmentId) ?? "",
                  inspectionId: inspectionData.inspection.id,
                  inspectionTemplateId:
                    inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
                      .id,
                },
                inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
                  .configuration
              );
              if (status === "changeOccurred") {
                let {
                  type,
                  referenceType,
                  reference,
                  id,
                  name,
                  isDeleted,
                  inspectionTypeTemplateId,
                  inspectionId,
                  inspectionAssignmentId,
                  formId,
                  formConfigurationId,
                } = instanceCopy;

                promises.push(
                  uploadFormInstance({
                    variables: {
                      thoFormInstance: {
                        type,
                        requiresArchive: false,
                        referenceType,
                        reference,
                        id,
                        name,
                        isDeleted,
                        inspectionTypeTemplateId,
                        inspectionId,
                        inspectionAssignmentId,
                        formId,
                        formConfigurationId,
                        formJson: encode(instanceCopy),
                      },
                    },
                  })
                );
              }
            }
          });

          Promise.all(promises).then(() => {
            refetch({
              inspectionAssignmentId: inspectionAssignmentId ? inspectionAssignmentId : assignmentId,
            }).then(() => {
              setValidatedAgainstTemplate(true);
              setInitializing(false);
            });
          });
        }
      }
    }
  }, [formInstancesData]);

  const getSortedFormInstances = () => {
    return formInstancesData && inspectionTypeData
      ? JSON.parse(JSON.stringify(formInstancesData.thoFormInstances)).sort(
          (prev: any, next: any) => {
            const forms =
              inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
                .configuration.forms;
            return (
              forms.findIndex((form: any) => form.id === prev.formId) -
              forms.findIndex((form: any) => form.id === next.formId)
            );
          }
        )
      : [];
  };

  return (
    <>
      {!validatedAgainstTemplate && <CircularProgress />}
      {validatedAgainstTemplate && (
        <Controller
          inspection={inspectionData.inspection}
          inspectionAssignment={inspectionAssignmentData.inspectionAssignment}
          formInstances={getSortedFormInstances()}
          formConfig={
            inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
              .configuration
          }
        >
          <>{children}</>
        </Controller>
      )}
    </>
  );
};

const generateInstance = (
  configuration: Configuration,
  formId: string,
  inspectionTypeTemplateId: string,
  inspectionId: string,
  inspectionAssignmentId: string,
  inspectorName: string,
  controlNumber: string,
  firmId: string,
  personId: string
): ITopLevelFormJson => {
  const topLevelInstanceId = uuidv4();
  const formMap = getFormsIndex(configuration);
  const form = formMap.formMap[formId].form;
  const date = new Date();
  const now = date.toISOString();
  return {
    id: topLevelInstanceId,
    inspectionTypeTemplateId: inspectionTypeTemplateId,
    parentFormId: null,
    formId: formId,
    inspectionId: inspectionId,
    parentFormInstanceId: null,
    type: form.type,
    isVisible: true,
    controlNumber: controlNumber,
    inspectorName: inspectorName,
    isDirty: true,
    entityId: null,
    inspectionAssignmentId: inspectionAssignmentId,
    formConfigurationId: configuration.id,
    isValid: true,
    referenceType: "Appointment",
    fields: getFields({
      form,
      formJsonId: topLevelInstanceId,
      firmId,
      personId,
      inspectionId,
      inspectionAssignmentId,
      inspectionTemplateId: inspectionTypeTemplateId,
      now,
    }),
    isEmbedded: false,
    reference: inspectionAssignmentId,
    name: form.name,
    isDeleted: false,
    subFormInstances: recurseSubformInstances({
      configuration,
      parentFormId: formId,
      inspectionAssignmentId,
      inspectionTypeTemplateId,
      inspectionId,
      parentFormInstanceId: topLevelInstanceId,
      firmId,
      personId,
    }),
    needsInitialValidations: true,
    initialMapGenerated: false,
  };

  // return instance;
};

export type ExpressionFieldToCompare = {
  validationAction: Exclude<ValidationAction, null>;
  validationTrigger: ValidationTrigger;
  validationId: string;
  expressionId: string;
};
export type ExpressionLocation = {
  validationIndex: number;
  expressionIndex: number;
};

export type FormMap = {
  [formId: string]: {
    form: Form;
    fieldsIndexMap: {
      [fieldId: string]: number;
    };
    expressionsMap: {
      expressionByType: {
        [type: string]: string[];
      };
      expressionFieldToCompare: {
        [fieldId: string]: ExpressionFieldToCompare[];
      };
      expressionLocation: {
        [expressionId: string]: ExpressionLocation;
      };
    };
    validationFieldIndexMap: {
      [fieldId: string]: { validationId: string; index: number }[];
    };
  };
};

export type FormInstanceMap = {
  [formInstanceId: string]: {
    id: string;
    name: string;
    type: string;
    formId: string;
    isDirty: boolean;
    isValid: boolean;
    entityId: string | null;
    isDeleted: boolean;
    isVisible: boolean;
    reference: string;
    isEmbedded: boolean;
    inspectionId: string;
    parentFormId: string | null;
    referenceType: string;
    formConfigurationId: string;
    parentFormInstanceId: string | null;
    inspectionAssignmentId: string;
    needsInitialValidations: boolean;
    inspectionTypeTemplateId: string;
    fields: IFormField[];
    subFormInstances: IFormJson[];
    initialMapGenerated: boolean;
  };
};

export type FormInstanceIdMap = {
  [formInstanceId: string]: {
    id: string;
    formId: string;
    parentFormId: string | null;
    parentFormInstanceId: string | null;
  };
};

export type FormMapWithOrder = {
  formMap: FormMap;
  formIndexMap: {
    [formId: string]: number;
  };
};

export function getFormsIndex(formConfig: Configuration) {
  let formsMap: FormMapWithOrder = { formIndexMap: {}, formMap: {} };
  formConfig.forms.forEach((form, i) => {
    formsMap.formIndexMap[form.id] = i;
    let expressionMap: FormMap[string]["expressionsMap"] = {
      expressionFieldToCompare: {},
      expressionLocation: {},
      expressionByType: {},
    };
    let validationFieldIndexMap: FormMap[string]["validationFieldIndexMap"] =
      {};

    form.validations.forEach((val, valIndex) => {
      val.expressions.forEach((exp, expIndex) => {
        if (exp.type) {
          let typeIterable = expressionMap.expressionByType[exp.type] ?? [];
          expressionMap.expressionByType[exp.type] = [...typeIterable, exp.id];
        }
        if (exp.fieldToCompare) {
          let iterable =
            expressionMap.expressionFieldToCompare[exp.fieldToCompare] ?? [];
          expressionMap.expressionFieldToCompare[exp.fieldToCompare] = [
            ...iterable,
            {
              validationAction: val.action as Exclude<ValidationAction, null>,
              validationTrigger: val.onTrigger as ValidationTrigger,
              expressionId: exp.id,
              validationId: val.id,
            },
          ];
        }
        expressionMap.expressionLocation[exp.id] = {
          validationIndex: valIndex,
          expressionIndex: expIndex,
        };
      });
      if (!validationFieldIndexMap[val.field]) {
        validationFieldIndexMap[val.field] = [];
      }
      validationFieldIndexMap[val.field].push({
        index: valIndex,
        validationId: val.id,
      });
    });

    let formFieldIndex: { [fieldId: string]: number } = {};
    form.fields.forEach((field, i) => {
      formFieldIndex[field.id] = i;
    });
    formsMap.formMap[form.id] = {
      form,
      expressionsMap: expressionMap,
      fieldsIndexMap: formFieldIndex,
      validationFieldIndexMap,
    };
  });

  return formsMap;
}

export function getFormInstancesIdIndex(formInstances: IFormJson[]) {
  let result: any = {};
  for (let i = 0; i < formInstances.length; i++) {
    let index = getFormInstanceIdIndex(formInstances[i], {});
    for (var key in index) {
      result[key] = index[key];
    }
  }
  return result;
}

export function getFormInstanceIdIndex(
  formJson: IFormJson,
  formInstancesMap: FormInstanceIdMap
) {
  let resultFormInstancesMap: FormInstanceIdMap = formInstancesMap;

  resultFormInstancesMap[formJson.id] = {
    id: formJson.id,
    formId: formJson.formId,
    parentFormId: formJson.parentFormId,
    parentFormInstanceId: formJson.parentFormInstanceId,
  };
  if (formJson.subFormInstances) {
    for (let i = 0; i < formJson.subFormInstances.length; i++) {
      resultFormInstancesMap = getFormInstanceIdIndex(
        formJson.subFormInstances[i],
        resultFormInstancesMap
      );
    }
  }
  return resultFormInstancesMap;
}

export function getFormInstancesIndex(formInstances: IFormJson[]) {
  let result: any = {};
  for (let i = 0; i < formInstances.length; i++) {
    let index = getFormInstanceIndex(formInstances[i], {});
    for (var key in index) {
      result[key] = index[key];
    }
  }
  return result;
}

export function getFormInstanceIndex(
  formJson: IFormJson,
  formInstancesMap: FormInstanceMap
) {
  let resultFormInstancesMap: FormInstanceMap = formInstancesMap;

  resultFormInstancesMap[formJson.id] = {
    id: formJson.id,
    name: formJson.name,
    type: formJson.type,
    formId: formJson.formId,
    isDirty: formJson.isDirty,
    isValid: formJson.isValid,
    entityId: formJson.entityId,
    isDeleted: formJson.isDeleted,
    isVisible: formJson.isVisible,
    reference: formJson.reference,
    isEmbedded: formJson.isEmbedded,
    inspectionId: formJson.inspectionId,
    parentFormId: formJson.parentFormId,
    referenceType: formJson.referenceType,
    formConfigurationId: formJson.formConfigurationId,
    parentFormInstanceId: formJson.parentFormInstanceId,
    inspectionAssignmentId: formJson.inspectionAssignmentId,
    needsInitialValidations: formJson.needsInitialValidations,
    inspectionTypeTemplateId: formJson.inspectionTypeTemplateId,
    fields: formJson.fields,
    subFormInstances: formJson.subFormInstances,
    initialMapGenerated: formJson.initialMapGenerated,
  };
  if (formJson.subFormInstances) {
    for (let i = 0; i < formJson.subFormInstances.length; i++) {
      resultFormInstancesMap = getFormInstanceIndex(
        formJson.subFormInstances[i],
        resultFormInstancesMap
      );
    }
  }
  return resultFormInstancesMap;
}

export const recurseSubformInstances = ({
  configuration,
  parentFormId,
  formsMap,
  inspectionAssignmentId,
  inspectionId,
  inspectionTypeTemplateId,
  parentFormInstanceId,
  firmId,
  personId,
}: {
  configuration: Configuration;
  parentFormId: string;
  formsMap?: FormMapWithOrder;
  inspectionAssignmentId: string;
  inspectionTypeTemplateId: string;
  inspectionId: string;
  parentFormInstanceId: string;
  firmId: string;
  personId: string;
}): IFormJson[] => {
  let map = formsMap ? formsMap : getFormsIndex(configuration);
  const parentChildFormIds = map.formMap[parentFormId].form.subformIds;
  const date = new Date();
  const now = date.toISOString();

  return parentChildFormIds.map((id) => {
    const childForm = map.formMap[id].form;
    const formJsonId = uuidv4();
    return {
      needsInitialValidations: true,
      entityId: null,
      isDeleted: false,
      isDirty: false,
      id: formJsonId,
      formId: id,
      formConfigurationId: configuration.id,
      inspectionAssignmentId,
      isValid: true,
      inspectionId,
      isVisible: true,
      name: childForm.name,
      parentFormId,
      reference: inspectionAssignmentId,
      referenceType: "WebView",
      inspectionTypeTemplateId,
      type: childForm.type,
      isEmbedded: childForm.isEmbedded,
      parentFormInstanceId: parentFormInstanceId ?? null,
      subFormInstances: recurseSubformInstances({
        configuration,
        parentFormId: id,
        formsMap: map,
        inspectionAssignmentId,
        inspectionId,
        inspectionTypeTemplateId,
        parentFormInstanceId: parentFormInstanceId,
        firmId,
        personId,
      }),
      fields: getFields({
        form: childForm,
        formJsonId,
        firmId,
        personId,
        inspectionId,
        inspectionAssignmentId,
        inspectionTemplateId: inspectionTypeTemplateId,
        now,
      }),
      initialMapGenerated: false,
    };
  });
};

export type FieldProperties = {
  now: string;
  form: Form;
  formJsonId: string;
  firmId: string;
  personId: string;
  inspectionId: string;
  inspectionAssignmentId: string;
  inspectionTemplateId: string;
};

const getFields = (props: FieldProperties) => {
  const date = new Date();
  const now = date.toISOString();

  //
  return props.form.fields.map((field) => getField(props, now, field));
};

export const getField = (
  props: FieldProperties,
  dateTimeISOString: string,
  field: Field
) => {
  const {
    form,
    formJsonId,
    firmId,
    personId,
    inspectionId,
    inspectionAssignmentId,
    inspectionTemplateId,
  } = props;
  const {
    displayIndex,
    description,
    inputType,
    id: formFieldId,
    name,
    isVisible,
    label,
    referenceMaterialIds,
  } = field;

  let valueType: keyof IFormValue;

  switch (field.inputType) {
    case "Time":
    case "Year":
    case "Date":
    case "DateTime":
      valueType = "beginDateValue";
      break;
    case "DropDown":
    case "LongText":
    case "Text":
    case "Phone":
    case "OptionSelect":
    case "Email":
      valueType = "textValue";
      break;
    case "Checklist":
      valueType = "textValues";
      break;
    case "Number":
      valueType = "doubleValue";
      break;
    case "YesNo":
      valueType = "booleanValue";
      break;
    case "PhotoCollection":
      valueType = "photoIds";
      break;
    case "Signature":
      // TODO
      valueType = "textValue";
      break;
    case "MapCapture":
      valueType = "jsonValue";
      break;
    case "Description":
      valueType = "textValue";
      break;
    default:
      exhaustiveGuard(field);
  }

  const formFieldInstanceId = uuidv4();
  const formInputId = uuidv4();
  let formField: IFormField = {
    displayIndex,
    description,
    inputType,
    name,
    formInstanceId: formJsonId,
    id: formFieldInstanceId,
    formFieldId,
    formId: form.id,
    formInput: {
      formValue: {
        id: formInputId,
        createdAt: dateTimeISOString,
        beginDateValue: null,
        booleanValue: null,
        createdBy: personId,
        doubleValue: null,
        endDateValue: null,
        firmId: firmId,
        entityId: "test",
        formConfigurationId: form.formConfigurationId,
        formFieldId,
        formId: form.id,
        formFieldInstanceId,
        inspectionAssignmentId,
        inspectionId,
        formInstanceId: formJsonId,
        inspectionTemplateId,
        integerValue: null,
        isDirty: false,
        isDeleted: false,
        jsonValue: null,
        name: label,
        parentId: null,
        photoIds: null,
        textValue: null,
        textValues: null,
        referenceType: "form",
        valueType,
      },
      errors: [],
      id: formFieldInstanceId,
      formFieldId,
      isDeleted: false,
      isDirty: false,
      isValid: true,
      formFieldInstanceId,
    },
    isDeleted: false,
    isDirty: false,
    isVisible,
    label,
    parentFormFieldId: null,
    parentFormFieldInstanceId: null,
    referenceMaterialIds: referenceMaterialIds ? referenceMaterialIds : null,
  };
  return formField;
};

const getUninitializedTopLevelForms = (
  forms: Form[],
  formInstances: IFormInstance[]
) => {
  let uninitializedFormTypes: string[] = [];
  forms.forEach((form: Form) => {
    if (
      AppointmentLevelFormTypes.some((type) => type === form.type) ||
      SpecialFormTypes.some((type) => type === form.type)
    ) {
      let included = false;
      formInstances.forEach((fi: IFormInstance) => {
        if (fi.formId === form.id) included = true;
      });
      if (!included) {
        uninitializedFormTypes.push(form.id);
      }
    }
  });
  return uninitializedFormTypes;
};
