// scroll bar
import "simplebar/src/simplebar.css";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";

//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

// ----------------------------------------------------------------------
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";

LicenseInfo.setLicenseKey(
  "56d41c79c80fff35ce447caa0d92bf01Tz03Nzg3MSxFPTE3MzA1MDk0MjAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

// // ----------------------------------------------------------------------

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});
const TOKEN_NAME = "authToken";

//console.log("STARTING CONNECTION WITH: ", process.env.REACT_APP_GRAPHQL_URL);
function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
const authLink = setContext((_, { headers }) => {
  const cookie = getCookie(TOKEN_NAME);
  // get the authentication token from local storage if it exists
  let token = cookie ? cookie : null;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <App />
        </SnackbarProvider>
      </ApolloProvider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
